export class BaseModel {

    static fromObjectWithSnakeCase(sourceObject, instance, log?: boolean) {
        Object.keys(sourceObject).forEach((propKeySnakeCase) => {
            const propKey = propKeySnakeCase.replace(/([-_]\w)/g, g => g[1].toUpperCase());
            if (!!log) {
                console.log(propKey, sourceObject[propKeySnakeCase]);
            }
            instance[propKey] = sourceObject[propKeySnakeCase];
        });
        return instance;
    }

    static fromObject(sourceObject, instance) {
        Object.keys(sourceObject).forEach((key) => {
            if (!!sourceObject[key]) {
                instance[key] = sourceObject[key];
            } else {
                instance[key] = null;
            }
        });
        return instance;
    }

    toObjectWithSnakeCase(removeNulls?) {
        const object = {};
        for (let camelCaseKey of Object.keys(this)) {
            if (!!removeNulls === true && !!this[camelCaseKey] === false) { continue; }

            if (camelCaseKey[0] === '_') {
              camelCaseKey = camelCaseKey.substring(1);
            }

            const snakeCaseKey = camelCaseKey.replace(/[\w]([A-Z])/g, m => `${m[0]}_${m[1]}`).toLowerCase();
            object[snakeCaseKey] = this[camelCaseKey];
        }
        return object;
    }

    toObject(removeNulls?) {
        const object = {};
        for (let key of Object.keys(this)) {
            if (!!removeNulls === true && !!this[key] === false) { continue; }

            if (key[0] === '_') {
              key = key.substring(1);
            }

            object[key] = this[key];
        }
        return object;
    }
}
