import { MaintenanceService } from 'src/app/services/maintenance.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppStorageService } from './app-storage.service';
import { UserService } from './user.service';
import { PreventAppUseReasons } from './prevent-app-use-reasons';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  loggedUser = null;
  public updateforza: boolean =false;

  constructor(
    private router: Router,
    private appStorageService: AppStorageService,
    private maintenanceService: MaintenanceService,
    private userService: UserService
  ) {
    this.subscribeToLoggedUser();
  }

  async subscribeToLoggedUser() {
    this.loggedUser = await this.userService.getLoggedUser();
    this.userService.loggedUserObservable.subscribe((loggedUser) => {
      if (!!loggedUser) {
        this.loggedUser = loggedUser;
      }
    });
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const accessToken = await this.appStorageService.get('accessToken');

    if (!!this.loggedUser === false) {
      this.loggedUser = await this.userService.getLoggedUser();
    }

    if (!!this.loggedUser === false || !!accessToken === false) {
      this.router.navigate(['/login'], { queryParams: { return: state.url }});
      return false;
    }

    // Check if user need to be blocked because too outdated
    // const updateRequired = await this.maintenanceService.updateRequired();
    // if (!!updateRequired || this.updateforza) {
    //   console.log("forzo ",this.updateforza)
    //   this.router.navigate(['/prevent-app-use', PreventAppUseReasons.OutdatedApp]);
    //   return false;
    // }


    if (!!this.loggedUser && !!accessToken && this.loggedUser.privacy_policy_accepted === false) {
      this.router.navigate(['/legals'], { queryParams: { return: state.url }});
      return false;
    }

    return true;
  }
}
