import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient
  ) { }

  async httpGet(endpoint: any, params: any = {}, token: string = null): Promise<any|boolean> {
    return new Promise(async (resolve) => {

      // Prepare headers
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json');

      if (!!token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }

      const httpOptions = {
        headers,
        params
      };

      const requestUrl = `${environment.url}${endpoint}`;
      this.httpClient.get(requestUrl, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (!!response.error) {
            console.log('ApiService.httpGet() then() error', response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log('ApiService.httpGet() catch() error', error);
          return resolve(error);
        });
    });
  }

  async httpPost(endpoint: any, body: any = {}, token: string = null): Promise<any> {
    return new Promise(async (resolve) => {

      // Prepare headers
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json');

      if (!!token) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }

      const httpOptions = {
        headers
      };

      const requestUrl = `${environment.url}${endpoint}`;
      this.httpClient.post(requestUrl, body, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (!!response.error) {
            console.log('ApiService.httpPost() then() error', response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log('ApiService.httpPost() catch() error', error);
          return resolve(error.error);
        });
    });
  }

  async httpDelete(endpoint: any, params: any = {}, token: string = null): Promise<any> {
    return new Promise(async (resolve) => {

      // Prepare headers
      let headers = new HttpHeaders();
      headers = headers
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json');

      if (!!token) {
        console.log(token);
        headers = headers.set('Authorization', `Bearer ${token}`);
      }

      const httpOptions = {
        headers,
        body: params
      };

      const requestUrl = `${environment.url}${endpoint}`;
      this.httpClient.delete(requestUrl, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (!!response.error) {
            console.log('ApiService.httpDelete() then() error', response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log('ApiService.httpDelete() catch() error', error);
          return resolve(error.error);
        });
    });
  }
}
