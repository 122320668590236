<!-- <ion-header>
  <ion-grid class="ion-no-padding toolbar-bottom">
    <ion-row>
      <ion-col (click)="openRankinghelp()"
            class="ion-align-self-center ion-text-center ion-text-uppercase" size="2">
        <ion-button class="ion-no-margin gradient__primary-secondary"  expand="full">
          <ion-icon name="help-outline"> </ion-icon>
        </ion-button>
      </ion-col>
      <ion-col (click)="openRanking1()"
                class="ion-align-self-center ion-text-center ion-text-uppercase" size="5">
        <ion-button class="ion-no-margin gradient__primary-secondary"  expand="full">GIOCA</ion-button>
      </ion-col>
      <ion-col (click)="openRanking2()"
                class="ion-align-self-center ion-text-center ion-text-uppercase" size="5">
        <ion-button class="ion-no-margin gradient__primary-secondary"  expand="full">CLASSIFICA</ion-button>
      </ion-col>
      <!-- <ion-col  (click)="closeModal()"
                class="ion-align-self-center ion-text-center ion-text-uppercase" size="2">
        <ion-button class="ion-no-margin gradient__primary-secondary"  expand="full">
          <ion-icon name="close-outline"> </ion-icon>
        </ion-button>
      </ion-col> -->
    <!-- </ion-row>
  </ion-grid>
</ion-header> -->

<ion-content [fullscreen]="true">

<!--  -----------------------------------------------
             I N T E S T A Z I O N E
  ----------------------------------------------  -->
  <ion-grid *ngIf="!!this.rankingParams">
    <ion-row class="ion-align-items-center ion-text-center">
        <ion-col size="9" class="ion-align-items-center ion-text-center">
            <div class="heading ion-margin-top">
                <div class="heading-title">
                    <ion-text i18n>{{rankingParams.licence_title}}</ion-text>
                </div>
                <div class="heading-subtitle">
                    <ion-text i18n>{{rankingParams.n_day_withwinners}} </ion-text>
                </div>
            </div>
          </ion-col>
          <ion-col size="3">
            <ion-button (click)="activateNewStatus('ShowSelection')"
                        color="secondary" fill="outline" >
              opzioni
            </ion-button>
          </ion-col>
      </ion-row>
  </ion-grid>


<!--  -----------------------------------------------
             S E L E Z I O N E
  ----------------------------------------------  -->
  <div *ngIf="!!this.rankingParams">
    <div  *ngIf="status_ShowSelection">
      <br><br>
      <ion-grid>
        <ion-row>
          <ion-col size="1"></ion-col>
          <ion-col size="10" >

            <ion-list class="ion-no-padding">

            <ion-item color="light">
                <ion-label class="ion-text-center">
                  <b>OPZIONI</b>
                </ion-label>
                  <ion-button (click)="exitFromSelectionStatus()"
                            class="ion-no-padding"
                            slot="end" color="light">
                    <ion-icon slot="icon-only" name="close-outline"></ion-icon>
                </ion-button>
              </ion-item>

              <ion-item color="light" (click)="activateNewStatus('ShowLicence')">
                  <ion-label>MOSTRA IL PATENTINO</ion-label>
                </ion-item>


              <ion-item color="light" (click)="activateNewStatus('ShowRanking')">
              <ion-label >I MIEI RISULTATI</ion-label>
              <!-- <ion-select okText="Ok" cancelText="Esci"
                          (ionChange)="showSelectedRanking()"
                          [(ngModel)]="selectedrkanag_id" >
                  <ion-select-option *ngFor="let item of rankingDays"
                                    [value]="item.rkanag_id">
                      {{item.ranking_day}}
                  </ion-select-option>
                </ion-select>-->
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size="1"></ion-col>
        </ion-row>
      </ion-grid>

    </div>
  </div>


<!--  -----------------------------------------------
      M O S T R A    I L     P A T E N T I N O
  ----------------------------------------------  -->
  <div *ngIf="!!this.rankingParams">
    <div  *ngIf="status_ShowLicence">
      <br>
      <app-patentino (parametriAlChiamante)="riceviParametriDalPatentino($event)">
      </app-patentino>
    </div>
  </div>


<!--  -----------------------------------------------
             R A N K I N G
  ----------------------------------------------  -->
  <div *ngIf="!!this.rankingParams && !!this.myRanking">
    <div  *ngIf="status_ShowRanking">
      <br>
      <ion-grid>
        <!-- <ion-row>
          <ion-col size="12" class="heading-title ion-align-items-center ion-text-center">
            <i><small>le mie classifiche settimanali</small></i>
          </ion-col>
        </ion-row> -->
        <ion-row class="heading-title ion-align-items-center ion-text-center">
          <ion-col size="8">
            <!-- <i>la mia classifica</i> -->
            &nbsp;
          </ion-col>
          <ion-col size="2">
            <i>pos.</i>
          </ion-col>
          <ion-col size="2">
            <i>punti</i>
          </ion-col>
        </ion-row>
        <ion-row   *ngFor="let item of myRanking">
          <ion-col size="8"  class="heading-winner" color="medium">
            <ion-text>&nbsp; &nbsp;</ion-text>
            {{item.descr}}
          </ion-col>
          <ion-col size="2" class="heading-winner ion-align-items-center ion-text-center">
              <ion-text class="ion-align-self-center">&nbsp;
                <ion-badge color="medium" >{{item.n}}</ion-badge>
            </ion-text>
          </ion-col>
          <ion-col size="2"  class="heading-winner ion-align-items-center ion-text-center">
            <i><small>{{item.points_ofDay | number : '1.1-1'}}</small></i>
          </ion-col>
        </ion-row>
      </ion-grid>




    </div>



  </div>

  <ion-grid class="ion-align-items-center ion-text-center" *ngIf="this.rankingParams==null && loading==true">
    <br><br><br>
    <ion-row>
      <ion-col size="12" style="vertical-align: middle !important;">
        <div class="heading">
          <div class="heading-subtitle">La stagione non è ancora iniziata. <br> Il tuo patentino sarà disponibile dopo l'inizio del ranking.
          </div>
        </div>
      </ion-col>
  </ion-row>
  </ion-grid>


</ion-content>
