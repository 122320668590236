import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppStorageService } from './app-storage.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private httpClient: HttpClient,
    private appStorageService: AppStorageService
  ) { }

  async httpGet(url: any, params: any = {}): Promise<any|boolean> {
    return new Promise(async (resolve) => {
      const token = await this.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
        params
      };
      const requestUrl = `${environment.url}${url}`;
      this.httpClient.get(requestUrl, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (!!response.error) {
            console.log('DataService.httpGet() then() error', response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log('DataService.httpGet() catch() error', error);
          return resolve(error);
        });
    });
  }

  async httpPost(url: any, params: any = {}): Promise<any> {
    return new Promise(async (resolve) => {
      const token = await this.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        })
      };
      const requestUrl = `${environment.url}${url}`;
      this.httpClient.post(requestUrl, params, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (!!response.error) {
            console.log('DataService.httpPost() then() error', response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log('DataService.httpPost() catch() error', error);
          return resolve(error.error);
        });
    });
  }

  async httpDelete(url: any): Promise<any> {
    return new Promise(async (resolve) => {
      const token = await this.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        })
      };
      const requestUrl = `${environment.url}${url}`;
      this.httpClient.delete(requestUrl, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (!!response.error) {
            console.log('DataService.httpDelete() then() error', response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log('DataService.httpDelete() catch() error', error);
          return resolve(error.error);
        });
    });
  }

  async httpPatch(url: any, params: any = {}): Promise<any> {
    return new Promise(async (resolve) => {
      const token = await this.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        })
      };
      const requestUrl = `${environment.url}${url}`;
      this.httpClient.patch(requestUrl, params, httpOptions)
        .toPromise()
        .then((response: any) => {
          if (!!response.error) {
            console.log('DataService.httpPost() then() error', response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log('DataService.httpPost() catch() error', error);
          return resolve(error.error);
        });
    });
  }

  async getAccessToken() {
    const accessToken = await this.appStorageService.get('accessToken');
    return accessToken;
  }
}
