import { ToastService } from "src/app/services/toast.service";
import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { AlertController } from "@ionic/angular";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class RankingService {

  popup: any;

  constructor(
    private toastService: ToastService,
    private alertController:AlertController,
    private router: Router,
    private dataService: DataService
  ) { }

  async popFreeBoost() {

    if (this.popup!=1) {
      this.popup=1;
      const pop = await this.alertController.create({
        header: '',
        message: `SI E' CONCLUSA LA GIORNATA DI RANKING! E' STATO REGALATO UN BALLOTTAGGIO IN TENDENZA AL PRIMO CLASSIFICATO IN QUESTA SETTIMANA!`,
        cssClass: 'custom-alert-background',
        buttons: [{
          text: 'CLASSIFICHE',
          role: 'confirm',
          handler: async () => {
            console.log("vai alle classifiche")
            this.router.navigate(['/ranking2']);
            this.popup=0;
          }
        }, {
          text: 'CHIUDI',
          role: 'cancel',
          handler:async () => {
            this.popup=0;
          }
        }]
      });
      pop.present();
      }
  }


  // ---------------------------------------------------------------
  // ACCESSO AL DATA BASE //////////////////////////////////////////
  // ---------------------------------------------------------------
  async rkpSelect(vtable: string, vrkanagId: number, vuserId: number){
    const result = await this.rkpSelectCall(vtable, vrkanagId, vuserId, 'OK')
    let flerror:boolean = result.error
    let message:string = result.message
    let data:any = result.data
    if (flerror == true){return []}

    if (message.toUpperCase() != 'OK'){
      // console.log("rkpSelect() error X -> ", message);
      // this.toastService.presentErrToast(
      //   "RankingService/rkpSelect - ERRORE X: " + message + " nella store proc.  ***   table:"+ vtable + ", rkanagId:" + vrkanagId.toString() + ", userId:"+vuserId.toString() )
        return []}

    return data
  }

  async rikpWinners(vtable: string, vrkanagId: number, vuserId: number){
    const result = await this.rkpSelectCallWinners()
    let flerror:boolean = result.error
    let message:string = result.message
    let data:any = result.data
    if (flerror == true){return []}

    if (message.toUpperCase() != 'OK'){
      // console.log("rkpSelect() error X -> ", message);
      // this.toastService.presentErrToast(
      //   "RankingService/rkpSelect - ERRORE X: " + message + " nella store proc.  ***   table:"+ vtable + ", rkanagId:" + vrkanagId.toString() + ", userId:"+vuserId.toString() )
        return []}

    return data
  }

  rkpSelectCallWinners(user?: string): Promise<any> {
    return new Promise((resolve) => {
      const params: any = {
        vtable: "rkxwinnersall",
        verror: "OK",
        vuserId: user,
        vrkanagId: 0
      };

      this.dataService
        .httpGet("/ranking/rkp-select", params)
        .then((response) => {
          if (!!response.error) {
            return resolve(response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log("rkxwinnersall() error Y -> ", error);
          this.toastService.presentErrToast(
            "RankingService/rkxwinnersall - ERRORE Y: " + error + '   ***   PARAMETRI : '
          );

          return resolve(false);
        });
    });
  }


  rkpSelectCall(
    vtable: string,
    vrkanagId: number = 0,
    vuserId: number = 0,
    verror: string = "OK"
  ): Promise<any> {
    return new Promise((resolve) => {
      const params: any = {
        vtable: vtable,
        vrkanag_id: vrkanagId,
        vuser_id: vuserId,
        verror: verror,
      };

      this.dataService
        .httpGet("/ranking/rkp-select", params)
        .then((response) => {
          if (!!response.error) {
            return resolve(response);
          }
          return resolve(response);
        })
        .catch((error) => {
          console.log("rkpSelect() error Y -> ", error);
          this.toastService.presentErrToast(
            "RankingService/rkpSelect - ERRORE Y: " + error + '   ***   PARAMETRI : '+JSON.stringify(params)
          );

          return resolve(false);
        });
    });
  }
}
