<!-- <img src="/assets/images/ranking-patentino-coiTesti.png" width="350" height="200"> -->

<div class="overflow">

  <ion-card class="module">
    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row><ion-col><br></ion-col></ion-row>
      <ion-row>
        <ion-col size="2">&nbsp;</ion-col>
        <!-- <ion-col size="5">&nbsp; &nbsp; <ion-badge color="medium">xxx</ion-badge></ion-col> -->
        <ion-col size="5">
          <!-- &nbsp; &nbsp; &nbsp;&nbsp;<span class="textPosition">{{appo_n_atDay}}°</span> -->
        </ion-col>
        <ion-col size="5" class="textAqua">
          <span>RISULTATO<br>SETTIMANALE</span>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="1"></ion-col>
        <ion-col size="6">
          <br><br>
          <div class="ellipsis ion-padding-start">
            <span class="textAqua">
              FANTALLENATORE
            </span>
            <br>
            <span class="textWhite">
              {{ userName }}
            </span>
            <br>
            <span class="textAqua">POSIZIONE SETTIMANALE</span>
            <br>
            <span class="textWhite">
              {{ appo_n_atDay | number : '1.0-0' }}
            </span>
          </div>

        </ion-col>
        <ion-col size="5">
          <div class="ellipsis" style="font-size: 1rem; line-height: 1;">
            <span class="textWhiteSmall">Ballottaggi votati: {{ appo_ballots_ofDay }}</span><br>
            <span class="textWhiteSmall">Ballottaggi giusti: {{ appo_ballotsOK_ofDay }}</span><br>
            <span class="textWhiteSmall">Ballottaggi sbagliati: {{ appo_ballotsWrong_ofDay }}</span><br>
            <hr color="white" width="110px" align="left">
            <span class="textWhiteSmall">PUNTI SETTIMANALI: {{ appo_points_ofDay | number : '1.0-0' }}</span><br>
            <span class="textWhiteSmall">CORRETTEZZA: {{ correct | number : '1.0-0' }}%</span>
            <br>
          </div>
        </ion-col>
      </ion-row>

    </ion-grid>
  </ion-card>

  <ion-grid class="ion-align-items-center ion-text-center padx">
    <ion-row>
      <ion-col size="12" style="vertical-align: middle !important;">
        <div class="heading">
          <div class="heading-subtitle">Il patentino mostra l'ultima giornata di Ranking a cui hai partecipato. Per
            partecipare devi votare tutti i ballottaggi proposti! Clicca il punto interrogativo in alto per maggiori
            info
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

</div>