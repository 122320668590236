<ion-content [fullscreen]="true">

<!--  -----------------------------------------------
             S E L E Z I O N E
  ------------------------------------------------>
  <div  *ngIf="status_ShowSelection" class="scores">
    <br><br>
    <ion-grid>
      <ion-row>
        <ion-col size="1"></ion-col>
        <ion-col size="10" >

          <ion-list class="ion-no-padding">

           <ion-item color="light">
              <ion-label class="ion-text-center">
                <b>CLASSIFICHE</b>
              </ion-label>
                <ion-button (click)="exitFromSelectionStatus()"
                          class="ion-no-padding"
                          slot="end" color="medium">
                <ion-icon slot="icon-only" name="close-outline"></ion-icon>
              </ion-button>

            </ion-item>
            <ion-item disabled="false" color="light" (click)="showLastRanking()">
              <ion-label>RANKING GENERALE</ion-label>
            </ion-item>

            <ion-item disabled="false" color="light">
              <ion-label >RANKING SETTIMANALE</ion-label>
              <ion-select okText="Ok" cancelText="Esci"
                        (ionChange)="showSelectedRanking()"
                        [(ngModel)]="selectedrkanag_id" >
                <ion-select-option *ngFor="let item of rankingDaysOther"
                                  [value]="item.rkanag_id">
                    {{item.ranking_day}}
                </ion-select-option>
              </ion-select>
            </ion-item>

            <ion-item  disabled="false" color="light" (click)="showMyRanking()">
              <ion-label >I MIEI RISULTATI</ion-label>
            </ion-item>

          </ion-list>

        </ion-col>
        <ion-col size="1"></ion-col>
      </ion-row>
    </ion-grid>
    

  </div>


  <!--  -----------------------------------------------
             C L A S S I F I C HE
  ----------------------------------------------  -->
<div *ngIf="status_ShowRanking" class="scores">

  <div *ngIf="status_ShowStandardRanking">
  <!-- gruppo one  -->  

  <ion-grid >      
        <!-- <ion-row>
          <ion-col size="2" >
            <ion-text>&nbsp;</ion-text>
          </ion-col>
          <ion-col size="8">
            <ion-text>&nbsp;</ion-text>
          </ion-col>
          <ion-col size="2"  class="heading-subtitle ion-align-items-center ion-text-center">
            <i><small *ngIf="winners_one?.length>0">punti</small></i>
          </ion-col>
        </ion-row> -->

        <ion-row  *ngFor="let winner of winners_one; let i = index" [ngClass]="{ currentUser : loggedUser_id===winner.user_id}">
          <ion-col size="2" class="heading-winner" >
            <ion-text>&nbsp;</ion-text>
            <ion-badge *ngIf="this.rankingParams.winners_title==='CLASSIFICA GENERALE'" color="warning"> {{i+1}}</ion-badge>
            <ion-badge *ngIf="this.rankingParams.winners_title!='CLASSIFICA GENERALE'" color="warning"> {{winner.n}}</ion-badge>
          </ion-col>
          <ion-col size="8"  class="heading-winner" >
            {{winner.name}}
          </ion-col>
          <ion-col *ngIf="this.rankingParams.winners_title!='CLASSIFICA GENERALE'" size="2"  class="heading-winner ion-align-items-center ion-text-center">
            <i><small>{{winner.points_ofDay | number : '1.1-1'}}</small></i>
          </ion-col>

          <ion-col *ngIf="this.rankingParams.winners_title==='CLASSIFICA GENERALE'" size="2"  class="heading-winner ion-align-items-center ion-text-center">
            <i><small>{{winner.points_atDay | number : '1.1-1'}}</small></i>
          </ion-col>

        </ion-row>
           
      </ion-grid>

      <!-- gruppo two  -->
      <ion-grid >
        <ion-row  *ngFor="let winner of winners_two;  let i = index" class="ion-no-padding" [ngClass]="{ currentUser : loggedUser_id===winner.user_id}">
          <ion-col size="2"  class="heading-winner" >
            <ion-text>&nbsp;</ion-text>
            <ion-badge *ngIf="this.rankingParams.winners_title==='CLASSIFICA GENERALE'" color="medium"> {{this.winners_one.length+i+1}}</ion-badge>
            <ion-badge *ngIf="this.rankingParams.winners_title!='CLASSIFICA GENERALE'" color="medium"> {{winner.n}}</ion-badge>
          </ion-col>
          <ion-col size="8"  class="heading-winner">
            {{winner.name}}
          </ion-col>
          <ion-col *ngIf="this.rankingParams.winners_title!='CLASSIFICA GENERALE'" size="2"  class="heading-winner ion-align-items-center ion-text-center">
            <i><small>{{winner.points_ofDay | number : '1.1-1'}}</small></i>
          </ion-col>

          <ion-col *ngIf="this.rankingParams.winners_title==='CLASSIFICA GENERALE'" size="2"  class="heading-winner ion-align-items-center ion-text-center">
            <i><small>{{winner.points_atDay | number : '1.1-1'}}</small></i>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- gruppo three  -->
      <ion-grid >
        <ion-row  *ngFor="let winner of winners_three;  let i = index" [ngClass]="{ currentUser : loggedUser_id===winner.user_id}">
          <ion-col size="2"  class="heading-winner" >
            <ion-text>&nbsp;</ion-text>
            <ion-badge *ngIf="this.rankingParams.winners_title==='CLASSIFICA GENERALE'" color="tertiary"> {{this.winners_one.length+this.winners_two.length+i+1}}</ion-badge>
            <ion-badge *ngIf="this.rankingParams.winners_title!='CLASSIFICA GENERALE'" color="tertiary"> {{winner.n}}</ion-badge>
          </ion-col>
          <ion-col size="8"  class="heading-winner">
              {{winner.name}}
          </ion-col>
          <ion-col *ngIf="this.rankingParams.winners_title!='CLASSIFICA GENERALE'" size="2"  class="heading-winner ion-align-items-center ion-text-center">
            <i><small>{{winner.points_ofDay | number : '1.1-1'}}</small></i>
          </ion-col>

          <ion-col *ngIf="this.rankingParams.winners_title==='CLASSIFICA GENERALE'" size="2"  class="heading-winner ion-align-items-center ion-text-center">
            <i><small>{{winner.points_atDay | number : '1.1-1'}}</small></i>
          </ion-col>
        </ion-row>
      </ion-grid>
  </div>
  <!-- la classifica individuale, comulativa  -->
  <div *ngIf="status_ShowMydRanking" class="scores_top">
    <ion-grid>
      <ion-row class="heading-title ion-align-items-center ion-text-center">
        <ion-col size="8">
          <!-- <i>la mia classifica</i> -->
          &nbsp;
        </ion-col>
        <ion-col size="2">
          <i>pos.</i>
        </ion-col>
        <ion-col size="2">
          <i>punti</i>
        </ion-col>
      </ion-row>
      <ion-row   *ngFor="let item of myRanking">
        <ion-col size="8"  class="heading-winner" color="medium">
          <ion-text>&nbsp; &nbsp;</ion-text>
          {{item.descr}}
        </ion-col>
        <ion-col size="2" class="heading-winner ion-align-items-center ion-text-center">
            <ion-text class="ion-align-self-center">&nbsp;
              <ion-badge color="medium" >{{item.n}}</ion-badge>
          </ion-text>
        </ion-col>
        <ion-col size="2"  class="heading-winner ion-align-items-center ion-text-center">
          <i><small>{{item.points_ofDay | number : '1.1-1'}}</small></i>
          <!-- <i><small>{{item.points_atDay | number : '1.1-1'}}</small></i> -->
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>

<!--  -----------------------------------------------
             I N T E S T A Z I O N E
  ----------------------------------------------  -->
  <ion-grid *ngIf="!!this.rankingParams" class="top-bar">
    
    <ion-row class="ion-align-items-center ion-text-center">
        <ion-col size="9" class="ion-align-items-center ion-text-center">
            <div class="heading ion-margin-top">
                <div class="heading-title">
                    <ion-text i18n>{{rankingParams.winners_title}}</ion-text>
                </div>
                <div class="heading-subtitle">
                    <ion-text *ngIf="this.rankingParams.winners_title!='CLASSIFICA GENERALE'" i18n>{{pageSubTitle}} </ion-text>
                    <ion-text *ngIf="this.rankingParams.winners_title==='CLASSIFICA GENERALE'" i18n></ion-text>
                </div>
            </div>
          </ion-col>
          <ion-col size="3">
            <ion-button (click)="activateSelectStatus()"
                        class="ion-no-padding"
                        color="secondary" fill="outline">
              <br>classifiche<br>&nbsp;
            </ion-button>
          </ion-col>
          <ion-col size="10" style="color: white;" *ngIf="status_ShowRanking ">
            <div style="text-align: initial;"> 
            <i>
              <span *ngIf="noposition && !status_ShowMydRanking">La tua posizione:&nbsp;<span class="color-text"><b>{{position}}</b></span></span>
            </i>
            </div>
          </ion-col>
          <ion-col size="2"  class="heading-subtitle-right" *ngIf="status_ShowRanking && !status_ShowMydRanking">
            <div>
            <span><i><small *ngIf="winners_one?.length>0">punti</small></i></span>
          </div>
          </ion-col>
      </ion-row>
  </ion-grid>


 <!--  -------------------------------------------------------
        L A      C L A S S I F I C A      P E R S O N A L E
  ---------------------------------------------------------  -->
  <ion-grid class="footer ion-no-padding" *ngIf="!!this.rankingParams" >
    <!-- <ion-grid class="footer ion-no-padding" *ngIf="rankingParams.myPersonalPoints_atDay>0"> -->

    <ion-row>
        <ion-col size="12" class="ion-align-items-center ion-text-center">
           
          </ion-col>
      </ion-row>
  </ion-grid>


  <ion-grid class="ion-align-items-center ion-text-center" *ngIf="this.rankingParams==null && loading==true" style="padding: 15px;">
    <ion-row>
      <ion-col size="12" style="vertical-align: middle !important;">
        <div class="heading">
          <div class="heading-subtitle">La stagione non è ancora iniziata. <br> Le classifiche saranno disponibili dopo l'inizio del ranking.
          </div>
        </div>
      </ion-col>
  </ion-row>
  </ion-grid>



</ion-content>
