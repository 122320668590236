import { UserService } from './../../services/user.service';
import { IrankingParams} from './../../models/ranking';
import { RankingService } from './../../services/ranking.service';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
//import { BallotsService } from 'src/app/services/ballots.service';
import { Ranking2Page } from '../ranking2/ranking2.page';
import { Ranking3Page } from '../ranking3/ranking3.page';
import { RankinghelpPage } from '../rankinghelp/rankinghelp.page';
import { RankingballotsPage } from '../rankingballots/rankingballots.page';
import { ActivatedRoute, Router } from '@angular/router';
import { parseISO } from 'date-fns';


@Component({
  selector: 'app-ranking1',
  templateUrl: './ranking1.page.html',
  styleUrls: ['./ranking1.page.scss'],
})
export class Ranking1Page implements OnInit {
  programmaChiamante:string ="Ranking1Page"
  rankingParams:IrankingParams
  title:string                      // titolo, sottotitolo e pulsante 'inizia'
  subtitle:string
  buttonText: string
  vote_completed: boolean=false;
  buttonDisabled: boolean
  phasecompute: any=null;
  buzz: any=null;
  ios=false;

  constructor(private modalController:ModalController,
              private rankingService:RankingService,
              private loadingController: LoadingController,
              private platform: Platform, 
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private router: Router) {
      if (this.platform.is('ios')) {
              this.ios=true;
      }
      if (!this.userService.isUserGuest()) {
        this.startbuzz();
      }

      this.activatedRoute.paramMap.subscribe( (params)=>{
        this.checkVote();
    });
      
  }

  async checkVote(){
    const attuale=new Date();    
    const appo = await this.rankingService.rkpSelect('rkanag', 0, 0)
    appo.forEach(element => {      
      let p= this.ios ? new Date(parseISO(element.dt_start)): new Date(element.dt_start); 
      if (p<=attuale) {
        this.rankingParams=element;
      }
    });
    try {
      const loggedUser = await this.userService.loggedUserObservable.getValue();    
      const appo1 = await this.rankingService.rkpSelect('rkxballots_voted', this.rankingParams.rkanag_id, loggedUser?.id)    // ballottaggi votati
        const rankingVotedBallots = appo1[0];
        if (rankingVotedBallots!=null){
          if (rankingVotedBallots.voted_ballots==rankingVotedBallots.n_ballots){
              this.vote_completed=true;
          }          
        }  
    } catch (error) {
      
    }
  }

  async startbuzz() {
    this.buzz= await this.loadingController.create({
      spinner: 'bubbles',
      translucent: true,
    })
    this.buzz.present();
  }

  async ngOnInit() {
   
  }

  riceviParametriDalCountdown($event:any){
    let params = $event
    this.title=params.ranking1Page_title
    this.subtitle=params.ranking1Page_subtitle
    this.phasecompute=params.phasecompute;

    //console.log(JSON.stringify(params))
    if (this.buzz!=null) {
      this.buzz=null;
      this.loadingController.dismiss();
  
    }

    if (params?.redirect) {
      this.router.navigate(['/ranking1']);
    }

    if (params.flButtonVote == true) {
      if (this.vote_completed==true) {
        // incongruenza in quanto l'utente ha votato ma risulta che deve ancora votare quindi stoppo tutto
        this.title="Voti in elaborazione"
        this.subtitle="Attendi l'elaborazione dei voti"
        this.buttonText = '---'
        this.buttonDisabled = true  
      } else {
        this.buttonText = 'VOTA'
        this.buttonDisabled = false  
      }
    }else {
      this.buttonText = '---'
      this.buttonDisabled = true
    }
  }
  // inizia il ranking
  beginRanking(){
    this.openRankingballots()
  }



  //-------------------------------------------------
  // chiusure/aperture pagine modali
  async openRankinghelp() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: RankinghelpPage,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }
  

  openRankingballots(){
    this.router.navigate(['/rankingballots']);
  }
  async openRankingballots_old() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: RankingballotsPage,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  async openRanking2() {
    //this.closeModal()
    // const modal = await this.modalController.create({
    //   component: Ranking2Page,
    //   componentProps: {
    //     modalMode: true,
    //     modalController: this.modalController
    //   }
    // });
    // await modal.present();
    // const { data } = await modal.onWillDismiss();
    this.router.navigate(['/ranking2']);
  }

  async openRanking3() {
    // this.closeModal()
    // const modal = await this.modalController.create({
    //   component: Ranking3Page,
    //   componentProps: {
    //     modalMode: true,
    //     modalController: this.modalController
    //   }
    // });
    // await modal.present();
    // const { data } = await modal.onWillDismiss();
    this.router.navigate(['/ranking3']);
  }

  async closeModal(){
    await this.modalController.dismiss()
  }


}
