import { Injectable } from '@angular/core';

// // Commented because does not buid with this library
import { AdLoadInfo, AdMob, AdMobBannerSize, AdMobRewardItem, AdOptions, BannerAdOptions, BannerAdPluginEvents, BannerAdPosition, BannerAdSize, InterstitialAdPluginEvents, RewardAdOptions, RewardAdPluginEvents } from '@capacitor-community/admob';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AdMobService {

  trackingAuthorizationStatus: boolean = false;
  hasActiveRewarded: boolean = false;

  constructor(private platform: Platform) {

  }

  async initialize(): Promise<void> {
    const { status } = await AdMob.trackingAuthorizationStatus();

    this.trackingAuthorizationStatus = (status === "authorized");

    if (status === 'notDetermined') {
      /**
       * If you want to explain TrackingAuthorization before showing the iOS dialog,
       * you can show the modal here.
       * ex)
       * const modal = await this.modalCtrl.create({
       *   component: RequestTrackingPage,
       * });
       * await modal.present();
       * await modal.onDidDismiss();  // Wait for close modal
       **/
    }

    await AdMob.initialize({
      requestTrackingAuthorization: true,
      testingDevices: [/*'cbd5f22e-dc35-4c41-bc83-5e9223f907cf'*/, /*'6F073B2BB613D4595621A84FDE494145'*/],
      initializeForTesting: environment.admob.isTesting,
    });
  }

  async hasTrackingAuthorization() {
    const status = await this.getPermissionStatus();
    if (status === 'authorized' || status === 'restricted') {
      return true;
    } else {
      return false;
    }
  }

  async getPermissionStatus() {
    //status	'authorized' | 'denied' | 'notDetermined' | 'restricted'
    const { status } = await AdMob.trackingAuthorizationStatus();
    return status;
  }

  async showBanner() {
    AdMob.addListener(BannerAdPluginEvents.FailedToLoad, (event: any) => {
      console.log('BannerAdPluginEvents.FailedToLoad -> ', event);
    });

    const options: BannerAdOptions = {
      adId: environment.admob.isTesting ? null : (this.platform.is('ios') ? environment.admob.ios.units.banner : environment.admob.android.units.banner),
      adSize: BannerAdSize.ADAPTIVE_BANNER,
      position: BannerAdPosition.BOTTOM_CENTER,
      margin: 0,
      isTesting: environment.admob.isTesting,
      npa: !this.trackingAuthorizationStatus
    };

    AdMob.showBanner(options)
      .then(() => {
        console.log('AdMob.showBanner() THEN');
      })
      .catch((error: any) => {
        console.log('AdMob.showBanner() CATCH', error);
      });
  }

  async showInterstitial() {
    AdMob.addListener(InterstitialAdPluginEvents.FailedToLoad, (event: any) => {
      console.log('InterstitialAdPluginEvents.FailedToLoad', event);
    });

    AdMob.addListener(InterstitialAdPluginEvents.FailedToShow, (event: any) => {
      console.log('InterstitialAdPluginEvents.FailedToShow', event);
    });

    AdMob.addListener(InterstitialAdPluginEvents.Loaded, (event: any) => {
      console.log('InterstitialAdPluginEvents.Loaded', event);
    });

    AdMob.addListener(InterstitialAdPluginEvents.Showed, () => {
      console.log('InterstitialAdPluginEvents.Showed');
    });

    AdMob.addListener(InterstitialAdPluginEvents.Dismissed, () => {
      console.log('InterstitialAdPluginEvents.Dismissed');
    });

    const options: AdOptions = {
      adId: environment.admob.isTesting ? null : (this.platform.is('ios') ? environment.admob.ios.units.interstitial : environment.admob.android.units.interstitial),
      isTesting: environment.admob.isTesting,
      npa: !this.trackingAuthorizationStatus
    };

    await AdMob.prepareInterstitial(options);
    await AdMob.showInterstitial();
  }

  async showRewardedAd(rewardCallback: CallableFunction = () => {}) {
    if (this.hasActiveRewarded) { return; }

    this.hasActiveRewarded = true;

    AdMob.addListener(RewardAdPluginEvents.FailedToLoad, () => {
      console.log('RewardAdPluginEvents.FailedToLoad');
      this.hasActiveRewarded = false;
    });

    AdMob.addListener(RewardAdPluginEvents.FailedToShow, () => {
      console.log('RewardAdPluginEvents.FailedToShow');
      this.hasActiveRewarded = false;
    });

    AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
      console.log('RewardAdPluginEvents.Loaded', info);
    });

    AdMob.addListener(RewardAdPluginEvents.Showed, () => {
      console.log('RewardAdPluginEvents.Showed');
    });

    AdMob.addListener(RewardAdPluginEvents.Dismissed, () => {
      console.log('RewardAdPluginEvents.Dismissed');
      this.hasActiveRewarded = false;
    });

    AdMob.addListener(RewardAdPluginEvents.Rewarded, (rewardItem: AdMobRewardItem) => {
      console.log('RewardAdPluginEvents.Rewarded');
      rewardCallback(rewardItem);
      this.hasActiveRewarded = false;
    });

    const options: RewardAdOptions = {
      adId: environment.admob.isTesting ? null : (this.platform.is('ios') ? environment.admob.ios.units.rewarded : environment.admob.android.units.rewarded),
      isTesting: environment.admob.isTesting,
      npa: !this.trackingAuthorizationStatus
      // ssv: {
      //   userId: "A user ID to send to your SSV"
      //   customData: JSON.stringify({ ...MyCustomData })
      //}
    };

    await AdMob.prepareRewardVideoAd(options);
    const rewardItem = await AdMob.showRewardVideoAd();
  }


}
