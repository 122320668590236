import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {

  private initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _storage: Storage|null = null;

  constructor(
    private storage: Storage
  ) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    this._storage = await this.storage.create();
    this.initialized.next(true);
  }

  async awaitInitialized(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.initialized.getValue() === true) { return resolve(true); }
      this.initialized.subscribe((initialized: boolean) => {
        if (initialized) { return resolve(true); }
      });
    });
  }

  public async set(key: string, value: any): Promise<any> {
    return new Promise(async (resolve) => {
      await this.awaitInitialized();
      this._storage?.set(key, value)
        .then((value: any) => {
          // console.log('set', {key, value});
          return resolve(value);
        })
        .catch((error) => {
          console.log('this._storage.set(key) catch() error -> ', error);
          return resolve(error);
        });
    });
  }

  public async get(key: string): Promise<any> {
    return new Promise(async (resolve) => {
      await this.awaitInitialized();
      this._storage?.get(key)
        .then((value: any) => {
          return resolve(value);
        })
        .catch((error) => {
          console.log('this._storage.get(key) catch() error -> ', error);
          return resolve(error);
        });
    });
  }

  public async remove(key: string) {
    await this.awaitInitialized();
    return await this._storage?.remove(key);
  }

  public async clear() {
    await this.awaitInitialized();
    return await this._storage?.clear();
  }

  public async keys() {
    await this.awaitInitialized();
    return await this._storage?.keys()
  }

  public async length() {
    await this.awaitInitialized();
    return await this._storage?.length()
  }

}
