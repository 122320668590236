import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { UserService } from './services/user.service';
import { CommonComponentsModule } from 'src/app/components/common-components.module';
import { BallotsService } from './services/ballots.service';
import { MaintenanceService } from './services/maintenance.service';
import { DataService } from './services/data.service';
import { CacheService } from './services/cache.service';
import { DatePipe } from '@angular/common';
import { PlayersService } from './services/players.service';
import { AppStorageService } from './services/app-storage.service';
import { BoostModule } from './components/boost/boost.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'md',
            animated: true
        }),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        CommonComponentsModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        HttpClientModule,
        UserService,
        BallotsService,
        AppStorageService,
        CacheService,
        DataService,
        MaintenanceService,
        DatePipe,
        PlayersService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
