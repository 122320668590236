import { IrankingWinners, IrankingParams, IrankingDays,IrankingUser } from './../../models/ranking';
import { RankingService } from './../../services/ranking.service';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';

import { Ranking1Page } from '../ranking1/ranking1.page';
import { Ranking3Page } from '../ranking3/ranking3.page';
import { RankinghelpPage } from '../rankinghelp/rankinghelp.page';

import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ranking2',
  templateUrl: './ranking2.page.html',
  styleUrls: ['./ranking2.page.scss'],
})
export class Ranking2Page implements OnInit {
  // parametri e giornate
  rankingParams:IrankingParams      // i parametri del ranking
  rankingDaysOther:IrankingDays[]   // le ALTRE giornate del ranking
  selectedrkanag_id: number         // il ranking selezionato, restituito da [(ngModel)]
  // classifiche, o rankings
  winners : IrankingWinners[]
  myRanking:IrankingUser[]
  rkanag_id: number                   // il ranking di interesse, non necessariamente votato
  rkanag_id_withwinners: number       // l'ultimo ranking con dei vincitori
  hasLastRanking:boolean = true       // un flag di controllo
  winners_one:IrankingWinners[]       // I tre gruppi (podio, meritevoli, altri) che vengono esposti 
  winners_two:IrankingWinners[]            
  winners_three:IrankingWinners[]          
  // stati della pagina (scelta o consultazione)
  status_ShowSelection: boolean=false    // true se si sta selezionando il ranking da esporre
  status_ShowRanking: boolean=true      // true se si sta visionando il ranking selezionato
  status_ShowStandardRanking: boolean=true       //  true:    i ranking delle gare
  status_ShowMydRanking: boolean=false           //  true:    i ranking delle gare
  previusStatus_ShowStandardRanking: boolean
  previusStatus_ShowMydRanking: boolean
  // variabili di servizio
  pageSubTitle:string                   // sottotitolo della pagina (il ranking selezionato)
  myPresences: number                   // partecipazioni al ranking
  myMedia: number                       // punteggio medio
  loggedUser_id: any;
  position: number;
  global: boolean=false;
  buzz: any;
  loading=false;
  noposition: boolean=false;
  constructor(private modalController:ModalController,
              private userService: UserService,
              private rankingService:RankingService,
              private loadingController: LoadingController,
              private router:Router) {
               
              }


  async startbuzz() {   
    this.buzz= await this.loadingController.create({
      spinner: 'bubbles',
      translucent: true,
    })
    this.buzz.present();
  }

  async dismissionBuzz(){
    if (this.buzz!=null) {
      this.buzz=null;
      this.loading=true;
      this.loadingController.dismiss();  
    }
  }

  async ngOnInit() {
    let loggedUser_id = 0
    const loggedUser = await this.userService.loggedUserObservable.getValue();
    if (!!loggedUser) {
      this.loggedUser_id = loggedUser.id
      loggedUser_id=loggedUser.id
    }

    const appo = await this.rankingService.rkpSelect('rkxparams_last', 0, 0)   
    if ((appo!=null) && (appo?.length>0)) this.startbuzz();  
    if (appo.length>0) {
      this.rankingParams = appo[0];   
      this.rkanag_id = this.rankingParams.rkanag_id
      this.rkanag_id_withwinners = this.rankingParams.rkanag_id_withwinners
      this.pageSubTitle = this.rankingParams?.n_day   
    } else  {
      this.rankingParams=null;
      this.loading=true;
      return;
    }

    const appo1 = await this.rankingService.rkpSelect('rkxanag', 0, 0)
    // this.rankingDaysOther = appo1.filter((item) => {
    //             return item.rkanag_id != this.rkanag_id;
    // });

    
    if (appo1.length>0) {      
      if (Date.parse(appo1[0].dt_compute)>new Date().getTime()){
        appo1.shift();
      }
    }
    this.rankingDaysOther = appo1;
    this.rankingDaysOther.sort((a, b) => a.rkanag_id - b.rkanag_id);
    console.log(this.rankingDaysOther)

   
    //TODO: Forzatura alla generale
     this.myRanking = await this.rankingService.rkpSelect('rkxusers', 0, loggedUser_id)    // i punteggi dell'utente
    //console.log("USER ->",this.myRanking)
//     const appo3 = await this.rankingService.rkpSelect('rkxwinners', this.rkanag_id_withwinners, 0)    // i vincitori dell'ultimo ranking
//     this.winners = appo3 // i punteggi dell'utente
//     if (this.winners.length>0 && this.myRanking.length>0){
// //      if (this.winners.length>0){
//       let appo = this.myRanking[0]
//       this.myPresences = appo.present
//       this.myMedia = appo.media
//       this.hasLastRanking = true
//     }
//     else{
//       this.myPresences = 0
//       this.myMedia = 0
//       this.hasLastRanking=false
//     }
//     console.log("chiamo init");
//     this.splitWinners(this.winners) 

    if (this.myRanking.length>0) { 
      this.myRanking[0].points_atDay;
      this.noposition=true;
    }
    this.status_ShowSelection = false
    this.status_ShowRanking = true
    this.status_ShowStandardRanking = true
    this.status_ShowMydRanking = false
    this.showLastRanking();
    this.global=true;

    this.dismissionBuzz();

    return;

  }

  // -----------------------------------------------------------
  //    SELEZIONI
  // -----------------------------------------------------------  
  
  async closeModal(){
    this.exitFromSelectionStatus()
    await this.modalController.dismiss()
  }

  activateSelectStatus(){ // Mostra le 'select'
    this.previusStatus_ShowStandardRanking = this.status_ShowStandardRanking
    this.previusStatus_ShowMydRanking = this.status_ShowMydRanking
    this.status_ShowSelection = true
    this.status_ShowRanking = false
    this.status_ShowStandardRanking = false
    this.status_ShowMydRanking = false
  }
  exitFromSelectionStatus(){// Nasconde la 'select', impregiudicati i sottostati del ranking
    this.status_ShowStandardRanking = this.previusStatus_ShowStandardRanking
    this.status_ShowMydRanking = this.previusStatus_ShowMydRanking
    this.status_ShowSelection = false    
    this.status_ShowRanking = true
  }
  async showLastRanking(){ // L'ULTIMO RANKING SELEZIONATO - CLASSIFICA GENERALE DI TUTTI!!!!
    this.status_ShowSelection = false
    this.status_ShowRanking = true
    this.pageSubTitle = this.rankingParams.n_day //this.rankingParams.winners_subtitle
    this.status_ShowStandardRanking = true
    this.status_ShowMydRanking = false
    this.global=true;
    const a: any = await this.rankingService.rkpSelectCallWinners(this.loggedUser_id);    // i vincitori dell'ultimo ranking
    this.winners=a.data;
    // const userdate: IrankingWinners = this.winners.find((x)=> { return x.user_id===this.loggedUser_id});
    // if (userdate) {
    //     this.position=userdate.n;
    // } else this.position=this.winners[this.winners.length].n+1;
    const index=this.winners.findIndex((x)=> { return x.user_id===this.loggedUser_id});
    if (index!=-1) {
      this.position=index+1;
    } else {
      this.position=this.winners.length+2;
    }
    this.splitWinners(this.winners)
    this.rankingParams.winners_title="CLASSIFICA GENERALE"

  }
  showMyRanking(){ // I PUNTEGGI DELL'UTENTE (tutte le giornate)
    this.status_ShowSelection = false
    this.status_ShowRanking = true
    this.pageSubTitle = 'I miei punteggi'
    this.status_ShowStandardRanking = false
    this.status_ShowMydRanking = true
    this.rankingParams.winners_title="I MIEI RISULTATI"
  }
  async showSelectedRanking(){ // IL RANKING SCELTO DALL'UTENTE
    this.status_ShowSelection = false
    this.status_ShowRanking = true
    this.status_ShowStandardRanking = true
    this.status_ShowMydRanking = false
    let appo = this.rankingDaysOther.filter(item => {
        return item.rkanag_id == this.selectedrkanag_id
    })   
    this.global=false;
    this.pageSubTitle = appo[0].ranking_day
    this.winners = await this.rankingService.rkpSelect('rkxwinners', this.selectedrkanag_id, 0)    // i vincitori del ranking selezionato
    const index=this.winners.findIndex((x)=> { return x.user_id===this.loggedUser_id});
    if (index!=-1) {
      this.position=index+1;
    } else {
      this.position=this.winners.length+2;
    } 
    this.splitWinners(this.winners)
    this.rankingParams.winners_title="CLASSIFICA SETTIMANALE"
    

    this.selectedrkanag_id = null
  }

  // -----------------------------------------------------------
  //    SEGMENTAZIONE DEL RANKING
  // -----------------------------------------------------------
  splitWinners(winners:IrankingWinners[]){ 
    this.winners_one = winners.filter((item) => {
      return item.n <= this.rankingParams.winners_one_last;
    });
    this.winners_two = winners.filter((item) => {
      return item.n > this.rankingParams.winners_one_last && item.n <= this.rankingParams.winners_two_last;
    });
    this.winners_three = winners.filter((item) => {
      return item.n > this.rankingParams.winners_two_last && item.n <= this.rankingParams.winners_three_last;
    });
    
  }

  // -----------------------------------------------------------
  //    APERTURA PAGINE MODALI
  // -----------------------------------------------------------
  openRankinghelp_old(){
    this.router.navigate(['/rankinghelp']);
  }
  
  async openRankinghelp() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: RankinghelpPage,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  openRanking1(){
    this.router.navigate(['/ranking1']);
  }

  async openRanking1_old() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: Ranking1Page,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  openRanking3(){
    this.router.navigate(['/ranking3']);
  }
  async openRanking3_old() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: Ranking3Page,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

}
function DateParse(dt_compute: string) {
  throw new Error('Function not implemented.');
}

