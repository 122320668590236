import { Communications } from './../../models/communications';
import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
//import { Ranking2Page } from 'src/app/pages/ranking2/ranking2.page';
import { Ranking1Page } from './../../pages/ranking1/ranking1.page';
import { UserService } from 'src/app/services/user.service';
import { CommunicationsService } from 'src/app/services/communications.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit {

  @Input() hide = false;
  @Input() IsNotRanking: boolean =true;

  programmaChiamante = 'MainHeaderComponent'    // parametro di Input per CountdownMinuteComponent
  credits;
  showCredits = false;
  currentPath;
  stopAnimation: Animation;
  animateCreditsIcon = false;
  isPlatformIos = false;
  comunication = null;
  blinkStart = true;
  intervalBlink: any;

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private userService: UserService,
    private platform: Platform,
    private communicationService: CommunicationsService,
    private alertController: AlertController,
    private modalController: ModalController,
    private router: Router
  ) { }

  ballBlinkLoop() {
    let time = 0;

    // 0 attivo
    this.blinkStart = true;

    this.intervalBlink = setInterval(()=> {
      time += 5000;

      // 5000 spento
      this.blinkStart = false;

      // ricominicia
      if (time === 15000) {
        clearInterval(this.intervalBlink);
        this.ballBlinkLoop();
      }

    }, 5000);
  }

  async ngOnInit() {

    try {
      const resComm = await this.communicationService.list();
      if (resComm?.length>0) { this.comunication= resComm[0].warning_message; this.ballBlinkLoop(); }

    } catch (error) {
      // Va avanti per la sua strada
      console.log("Errore nel rilevare le comunicazioni urgenti")
    }
    this.isPlatformIos = this.platform.is('ios');

    this.route.url.subscribe((val) => {
      this.currentPath = this.location.path();
    });



    this.userService.loggedUserObservable.subscribe((updatedUser) => {
      //console.log("Aggiornamento testata")
      const isUserPro = this.userService.isUserPro();
      const isGuest = this.userService.isUserGuest();
      //this.showCredits=!isUserPro; // Modifica a seguito dell'introduzione dei boost
      this.showCredits=true;
      console.log("UpdateUser", updatedUser)
      if (!!updatedUser === true  && isGuest === false) {
        console.log("entrato");
        if (!!this.credits) {
          // Animate
          this.animateCreditsIcon = true;
          const t = setTimeout(() => {
            this.animateCreditsIcon = false;
            clearTimeout(t);
          }, 2000);
        }
        this.showCredits = true;
        this.credits = updatedUser.points;
      }

    });
  }

  async openCommunication(){
    const alert = await this.alertController.create({
      header: 'Comunicazione',
      // subHeader: 'Comunicazione',
      message: this.comunication,
      buttons: ['OK'],
    });
    await alert.present();
    this.comunication=null;
  }

  changemenu(){
    if (this.comunication!=null) {
      this.openCommunication();
    } else {
      this.IsNotRanking=true;
      this.router.navigate(['/home']);
    }
  }


  async openRankingPage() {
    // const modal = await this.modalController.create({
    //   //component: Ranking2Page,
    //   component: Ranking1Page,
    //   componentProps: {
    //     modalMode: true,
    //     modalController: this.modalController
    //   }
    //   //cssClass: 'ranking-modal'
    // });
    // await modal.present();

    // const { data } = await modal.onWillDismiss();
    this.IsNotRanking=false;
    this.router.navigate(['/ranking1']);
  }

  message(){

  }

}
