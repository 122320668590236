export const environment = {

  /* General Settings */
  production: false,

  appBuildWeb: 133,
  appVersionWeb: '4.0.10',

  /* Production: New Api */
  baseUrl: 'https://v2.fantaballottaggi.it',
  url: 'https://v2.fantaballottaggi.it/api',

  /* Stage/Test Server */
  // baseUrl: 'https://app-dev.fantaballottaggi.it',
  // url: 'https://app-dev.fantaballottaggi.it/api',

  /* Local Env */
  // baseUrl: 'https://fantaballottaggi.lab',
  // url: 'https://fantaballottaggi.lab/api',

  minPlayerSearchCharacters: 2,
  splashScreenDuration: 7,
  refreshCurrentUserIntervalMs: 2 * (1000 * 60 * 60),

  /* Links */
  fantapazzDynamicLinkUrl: 'https://fantapazzapp.page.link/7Yoh',
  privacyPolicyUrl: 'https://www.fantaballottaggi.it/privacy-policy',
  termsAndConditionsUrl: 'https://www.fantaballottaggi.it/termini-e-condizioni',
  facebookUrl: 'https://www.facebook.com/fantaballottaggiOfficial',
  facebookPageId: 538972019955841,
  instagramUrl: 'https://instagram.com/fantaballottaggi?igshid=1ez5im3h0olzm',

  adsSettings: {
    enabled: false,
    interstitialEnabled: false,
    periodicInterstitialMinutes: 2,
    notificateVotesCountEveryN: 9
  },

  /* AdMob */
  admob: {
    enabled: true,
    isTesting: true,
    interstitialEnabled: true,
    ios: {
      appId: 'ca-app-pub-6806532292955027~6444556130',
      units: {
        banner: 'ca-app-pub-6806532292955027/1433651030',
        interstitial: 'ca-app-pub-6806532292955027/3973497150',
        rewarded: 'ca-app-pub-6806532292955027/2085916013'
      }
    },
    android: {
      appId: 'ca-app-pub-6806532292955027~2014356537',
      units: {
        banner: 'ca-app-pub-6806532292955027/6797191377',
        interstitial: 'ca-app-pub-6806532292955027/9964406034',
        rewarded: 'ca-app-pub-6806532292955027/1239522600'
      }
    }
  },

  /* Storage/Cache Keys */
  ENDING_DATES_STORAGE_KEY: 'endingDates',
  ENDING_DATE_TASKS_KEY: 'endingDateTasks',
  VOTED_BALLOTS_COUNT_KEY: 'VOTED_BALLOTS_COUNT',
  VIDEO_REWARDED: 'videorewarded',
  TIMES_REWARDED: 'videorewarded_times',

  /* Development */
  dev_disableSavingVotes: false,
  dev_disableAutoskipAfterVote: false,

  /* Push Notifications */
  pushNotificationsServerUrl: 'https://v2.fantaballottaggi.it',
  pushNotificationsServerPort: 4434,
  pushNotificationsServerAuthToken: 'K,[D=)|*Zo)f0K]0Aj7?dRHfzJ-$w+'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
