import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class RawQueriesService {

  constructor(
    private dataService: DataService
  ) { }

  public async select(query: string, bindings: any = {}, connection: string = 'mysql_ranking') {
    return new Promise(async (resolve: CallableFunction) => {
      const url = `/raw-queries/select/${connection}`;
      console.log(url)
      const params: any = { query, bindings };
      const result = await this.dataService.httpGet(url, params);
      return resolve(result);
    });
  }

  public async statement(query: string, bindings: any = {}, connection: string = 'mysql_ranking') {
    return new Promise(async (resolve: CallableFunction) => {
      const url = `/raw-queries/statement/${connection}`;
      const params: any = { query, bindings };
      const result = await this.dataService.httpPost(url, params);
      return resolve(result);
    });
  }

}
