
import { ToastService } from 'src/app/services/toast.service';
import { MaintenanceService } from './../../services/maintenance.service';
import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from 'src/app/helpers/helpers';
import { BallotsService } from 'src/app/services/ballots.service';
import { UserService } from 'src/app/services/user.service';
import { RankingService } from 'src/app/services/ranking.service';
import { IrankingParams } from 'src/app/models/ranking';
import { Router } from '@angular/router';
// import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-tb-ranking',
  templateUrl: './tb-ranking.component.html',
  styleUrls: ['./tb-ranking.component.scss'],
})
export class TBrankingComponent implements OnInit {
  @Output() closeRankingballotsPage: EventEmitter<any> = new EventEmitter();

  // Internal state
  ballots: Array<any>
  ballot: any
  loggedUser
  selected
  matrix
  animate = true;
  rankingParams: IrankingParams;

  ballots_number: number  // parametri ordinari per TbRankingVotesComponent
  ballots_voted: number
  label_forReturn: string

  // Observables per TbRankingVotesComponent
  ballotObservable: BehaviorSubject<any> =new BehaviorSubject<any>(null);
  ballotVotedObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  //ballotSkippedObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  matrixObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private userService: UserService,
    private ballotsService: BallotsService,
    private maintenanceService: MaintenanceService,
    private toastService:ToastService,
    private router: Router,
    private rankingService:RankingService
  ) { }

  async ngOnInit() {
    const appo = await this.rankingService.rkpSelect('rkxparams_last', 0, 0)
    this.rankingParams = appo[0];

    this.loggedUser = await this.userService.loggedUserObservable.getValue();
    // recupera  TUTTI i ballottaggi
    const ballots = await this.ballotsService.getBallotsByRankingId(this.rankingParams.rkanag_id);
    console.log(ballots)
    if(!!ballots) {
      this.ballots=this.ballotsService.improveBallotsList(ballots)
      this.ballots_number=this.ballots.length
      this.ballots_voted=await this.getNumbersOfBallots_forVotation(this.ballots_number)
      this.label_forReturn=this.getlabel_forReturn()
      this.ballot = this.ballots[this.ballots_voted] // estrae il primo ballottaggio da votare
      // -----------------------------------------------------------------------------
      // risolve i BehaviorSubject secondo le procedure di TurboBallotsRunnerComponent
      if (!!this.ballot){
        this.ballotObservable.next(this.ballot)
        this.ballotVotedObservable.next(null)
        this.matrix = Helpers.extractBallotMatrixToArray(this.ballot.matrix);
        this.matrixObservable.next(this.matrix)
        this.ballotObservable.subscribe((ballot) => { // Store next expiration_date only if is null (never set or deleted after clean)
        this.maintenanceService.onNewTurboBallot(ballot);
        });
      }
    }
  }
  async returnedFromVotations(event: any){
      if(!!this.ballots) {
        let flagExit = await this.getNumbersOfBallots_forVotation(this.ballots_number)
        if (flagExit == -1){
          this.toastService.presentSuccessToast("Hai completato il Ranking settimanale", 3000, 'middle');
          this.closeRankingballotsPage.emit(-1);
          this.router.navigate(['/home']);
         return
        }
        this.ballots_number=this.ballots.length
        this.ballots_voted= await this.getNumbersOfBallots_forVotation(this.ballots_number)
        this.label_forReturn=this.getlabel_forReturn()
        this.ballot = this.ballots[this.ballots_voted] // estrae il primo ballottaggio da votare
        this.ballotObservable.next(this.ballot)// risolve i BehaviorSubject
        this.matrix = Helpers.extractBallotMatrixToArray(this.ballot.matrix);
        this.matrixObservable.next(this.matrix)
        this.ballotVotedObservable.next(null);  //Azzera all'ingresso le precedenti votazioni
        // -----------------------------------------------------------------------------
        this.ballotObservable.subscribe((ballot) => {// Store next expiration_date only if is null (never set or deleted after clean)
          this.maintenanceService.onNewTurboBallot(ballot);
        });
      }
      console.log("returnedFromVotations")
  }

  async getNumbersOfBallots_forVotation(ballots_number){
    const appo = await this.rankingService.rkpSelect('rkxvotation_base2', this.rankingParams.rkanag_id, this.loggedUser.id)
    let ballottaggiVotati = appo.length
      if (ballottaggiVotati==this.rankingParams.ballots_n){return -1     // forzatura per l'uscita dalla pagina (fine del ranking)
      } else  {return ballottaggiVotati}
  }


  getlabel_forReturn(){
  // restituisce la label per la votazione
  let msg = "Prosegui"
  if(this.ballots_voted+1==this.ballots_number){msg = "Hai concluso il ranking"}
  return msg
  }

  async formationBallotVote(playersVoted: any) {
    this.voteBallot(JSON.stringify(playersVoted));
  }
  async voteBallot(playersVoted: any) {
    const voteData = {
      ballot_id: this.ballot.id,
      user_id: this.loggedUser.id,
      rkanag_id: this.rankingParams.rkanag_id,
      vote_selection: playersVoted
    };
    this.ballotsService.voteBallot(voteData)
    .then(async (voteResponse) => {
      // Append ballot creator name
      //voteResponse.ballot.creator = this.ballot.creator;
      // Show ballot percentages
      this.ballot = voteResponse.ballot;
      this.ballotVotedObservable.next(voteResponse.ballot);
      this.saveUpdatedUser(voteResponse.user);
      // console.log("TBrankingComponent/voteBallot-voteData", voteData);
      // console.log("TBrankingComponent/voteBallot-ballotVotedObservable", this.ballotVotedObservable);
      //this.updatedRankingVotedBallots(voteData)
    })
    .catch(async (error) => {
      console.log("TBrankingComponent/voteBallot - ERROR", error);
      //this.skipTurboBallot();
    });
  }
  saveUpdatedUser(user) {
    if (!!user === false) { return; }
    this.userService.setLoggedUser(user);
    this.loggedUser = user;
  }

}
