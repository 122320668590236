import { RankingService } from './../../services/ranking.service';
import { IrankingParams } from './../../models/ranking';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Ranking1Page } from '../ranking1/ranking1.page';
import { Ranking2Page } from '../ranking2/ranking2.page';
import { Ranking3Page } from '../ranking3/ranking3.page';

@Component({
  selector: 'app-rankinghelp',
  templateUrl: './rankinghelp.page.html',
  styleUrls: ['./rankinghelp.page.scss'],
})
export class RankinghelpPage implements OnInit {
  rankingParams:IrankingParams      // i parametri del ranking
  
  constructor(private modalController:ModalController,
              private rankingService:RankingService) { }

  async ngOnInit() {
    const appo = await this.rankingService.rkpSelect('rkparams_base', 0, 0)    
    this.rankingParams = appo[0];   
  }

  async openRanking1() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: Ranking1Page,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
      //cssClass: 'vote-modal'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  async openRanking2() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: Ranking2Page,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
      //cssClass: 'vote-modal'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  async openRanking3() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: Ranking3Page,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
      //cssClass: 'vote-modal'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  async closeModal(){
    await this.modalController.dismiss()
  }




}
