import { AlertController, Platform } from '@ionic/angular';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IrankingParams } from './../../models/ranking';
import { RankingService } from 'src/app/services/ranking.service';
import { RawQueriesService } from 'src/app/services/raw-queries.service';
import { parseISO } from 'date-fns';
import { AppStorageService } from 'src/app/services/app-storage.service';
/*
  -----------------------------------------------------------------------------------
    COUNTDOWN,  per i menu: risolve il minuto
              PAGINE CHIAMANTI:
                    AppComponent, per il menù principale: la grafica è vuota, ma restituisce la URL dell'icona e la stringa con la descrizione
                    MainHeaderComponent, per il menu in alto: restituisce l'intero oggetto

              INPUT:rankingParams, con:
                      dt_start:         l'inizio dellle votazioni per il ranking in essere
                      dt_end:           la fine delle votazioni per il ranking in essere
                      dtnesxt_start:    l'inizio del ranking successivo
              SERVIZIO
                    urlimg:             la URL dell'immagine, con eventuali pallini rossi e verdi
                    shortDescription:   descrizione minimale
                    longDescription:    countdown completo, al minuto
                    --------------------------------------------------------------
                    to_beginRanking:    ZERO, oppure il tempo di attesa al prossimo ranking
                    to_endRanking:      ZERO, oppure il tempo ancora disponibile per le votazioni
                    flVotingPeriod:     true se siamo nel periodo delle votazioni
                    flShowCountdown:    true per mostrare la riga del countdown
                    flVotationCompleted: true quando sono stati votati tutti i ballottaggi
  -----------------------------------------------------------------------------------

  -----------------------------------------------------------------------------------

  */

enum rankingperiods {
  before = 0,
  during = 1,
  after = 2,
  end = 3
}
@Component({
  selector: 'app-countdown-minute',
  templateUrl: './countdown-minute.component.html',
  styleUrls: ['./countdown-minute.component.scss'],
})
export class CountdownMinuteComponent implements OnInit {
  @Input() programmaChiamante
  @Output() parametriAlChiamante = new EventEmitter<any>()
  rankingParams: IrankingParams              // parametri del ranking
  days: number                             // tempi di attesa
  hours: number
  minutes: number
  seconds: number
  shortDescription: string
  longDescription: string
  statusDescription: string
  x: any;
  distance: number;
  period: rankingperiods | null;
  valuesperiod = rankingperiods;
  blinkStart = true;
  blinkStartDev = false;
  intervalBlink: any;
  verifyRanking: any;
  lastcomputed: IrankingParams | null;
  phasecompute: string;
  ios = false;

  constructor(private rankingService: RankingService,
    private platform: Platform,
    private appStorageService: AppStorageService,
    private queryservice: RawQueriesService
  ) {
    if (this.platform.is('ios')) {
      this.ios = true;
    }
  }


  ngOnChanges() {
    // rileva le variazioni del programma chiamante
    //alert("programmaChiamante : " + this.programmaChiamante)
  }

  async initRanking() {
    this.rankingParams = null;
    this.lastcomputed = null;
    const attuale = new Date();
    const appo = await this.rankingService.rkpSelect('rkanag', 0, 0)

    let rk = await this.appStorageService.get("computeRankingWeek");
    let rkdef = await this.appStorageService.get("computeRankingWeek");
    var targetdate = null;
    let targetd=null;

    appo.forEach(async (element) => {

      let start = this.ios ? new Date(parseISO(element.dt_start)) : new Date(element.dt_start);
      let end = this.ios ? new Date(parseISO(element.dt_end)) : new Date(element.dt_end);
      let compute = this.ios ? new Date(parseISO(element.dt_compute)) : new Date(element.dt_compute);

      // const popup =await this.appStorageService.get("viewComputeRankingWeek")
      //console.log(rk, attuale<new Date(parseISO(element.dt_compute)), attuale, element.dt_compute)

      targetd = this.ios ? new Date(parseISO(targetdate)) : new Date(targetdate);




      if ((attuale<=compute) && (targetdate==null) ) {
        targetdate=element.dt_compute;
        //console.log("targetdate: ",targetdate)
        if ((rk==null) || (rk!=targetdate) )  {
          await this.appStorageService.set("computeRankingWeek",targetdate);
          console.log("**rk: ",rk ,"set computeRankingWeek",targetdate)
        }
       }
      //  else {
      //   if ((pattuale>=rk) && (rk!=null)){
      //     console.log("CAMBIO!!!!",pattuale,targetd)
      //     await this.rankingService.popFreeBoost();
      //   }
      //  }


      if ((attuale <= end) && (this.rankingParams == null)) {
        this.rankingParams = element;
        if (attuale < start) {
          if (!!this.lastcomputed) {
            if (attuale <= new Date(parseISO(this.lastcomputed.dt_compute))) {
              console.log("I risultati dell'ultima settimana saranno pubblicati ", this.formatDateShort(this.lastcomputed.dt_compute));
              this.phasecompute = "I risultati dell'ultima settimana saranno pubblicati " + this.formatDateShort(this.lastcomputed.dt_compute)
              const rs = await this.checkComputeRankingWeek(this.lastcomputed.dt_compute);
            }
          }
        }
      }
      this.lastcomputed = element;
    });

    // popup per avviso calcolo ranking boost
    if (rkdef!=null){
      let rkdate = this.ios ? new Date(parseISO(rkdef)) : new Date(rkdef);
      if (attuale>=rkdate) {
        await this.rankingService.popFreeBoost();
      }
    }


    if (this.rankingParams == null) {
      //this.rankingParams=appo[appo.length-1]
      this.statusDescription = "non ci sono più ranking"
      // this.formatDescription()
      this.shortDescription = null
      this.longDescription = null;
      this.restituisciParametriAlChiamante()
      this.period = rankingperiods.end;
      clearInterval(this.x);
    } else {
      //clearInterval(this.verifyRanking);
      this.calculateTime();
      this.computePeriods();
    }

    this.ballBlinkLoop();
  }

  async checkComputeRankingWeek(dt_compute: string) {
    const rk = await this.appStorageService.get("computeRankingWeek");
    const popup =await this.appStorageService.get("viewComputeRankingWeek")
    //dt_compute="2024-03-07 09:00:00";
    console.log(popup, rk)
    if ((rk!=null) && (rk!=dt_compute)) {
        // visualizza popup
        if (popup!=1) {
          const t= await this.appStorageService.set("viewComputeRankingWeek",1)
          this.rankingService.popFreeBoost();
        }
      } else {
        const t= await this.appStorageService.set("viewComputeRankingWeek",0)
      }
    const computeRankingWeek = await this.appStorageService.set("computeRankingWeek",dt_compute);
  }



  async ngOnInit() {
    // -----------------------------------------------------------------------
    //      verifica lo stato della votazione
    // -----------------------------------------------------------------------
    // const appo = await this.rankingService.rkpSelect('rkxparams_last', 0, 0)        // parametri
    // console.log(appo);
    // // const app2 = await this.queryservice.select('SELECT * FROM rkanag',{},'mysql_ranking');
    // this.rankingParams = appo[0];

    this.initRanking();
    this.verifyRanking = setInterval(async () => {
      this.initRanking();
    }, 60000)
  }

  ballBlinkLoop() {
    let time = 0;

    // 0 attivo
    this.blinkStart = true;

    this.intervalBlink = setInterval(() => {
      time += 5000;

      // 5000 spento
      this.blinkStart = false;

      // ricominicia
      if (time === 35000) {
        clearInterval(this.intervalBlink);
        this.ballBlinkLoop();
      }

    }, 5000);
  }

  computePeriods() {
    this.x = setInterval(() => {
      //prima del rannking
      this.calculateTime();

    }, 5000);
  }

  calculateTime() {
    // let dt_start = this.ios ? Date.parse(parseISO(this.rankingParams.dt_start).toISOString()): Date.parse(this.rankingParams.dt_start);  // conversione in millisecondi
    // let dt_end = this.ios ? Date.parse(parseISO(this.rankingParams.dt_end).toISOString()): Date.parse(this.rankingParams.dt_end);
    // let dtnext_start = this.ios ? Date.parse(parseISO(this.rankingParams.dtnext_start).toISOString()): Date.parse(this.rankingParams.dtnext_start);

// console.log("ris",this.rankingParams?.dt_start===null,this.rankingParams?.dt_start)
      if (this.rankingParams?.dt_start==null) { return;}

    let dt_start = this.ios ? (new Date(parseISO(this.rankingParams.dt_start)).getTime()) : Date.parse(this.rankingParams.dt_start);  // conversione in millisecondi

    let dt_end = this.ios ? (new Date(parseISO(this.rankingParams.dt_end)).getTime()) : Date.parse(this.rankingParams.dt_end);

    let dtnext_start = null;
    if (this.rankingParams.dtnext_start != null) {
      dtnext_start = this.ios ? (new Date(parseISO(this.rankingParams.dtnext_start)).getTime()) : Date.parse(this.rankingParams.dtnext_start);
    }

    //    let dt_start = Date.parse(this.rankingParams.dt_start)  // conversione in millisecondi
    //    let dt_end = Date.parse(this.rankingParams.dt_end)
    //    let dtnext_start = Date.parse(this.rankingParams.dtnext_start)
    this.days = 0
    this.hours = 0
    this.minutes = 0
    this.seconds = 0
    // ---------------------------------------------------------
    //      FORZATURE PER LE SIMULAZIONI
    // let MS_PER_MINUTE = 60000
    // let _now =  new Date().getTime()
    // let dt_start = new Date(_now + 30 * MS_PER_MINUTE).getTime()
    // let dt_end =  new Date(_now + 70 * MS_PER_MINUTE).getTime()
    // let dtnext_start = new Date(_now + 80 * MS_PER_MINUTE).getTime()
    //this.flVotationCompleted = false
    // ---------------------------------------------------------
    let now = new Date().getTime();
    this.distance = 0  // distanza in millisecondi
    if (now <= dt_start) {
      this.distance = dt_start - now
      this.statusDescription = "prima della votazione"
      this.period = rankingperiods.before;
      // red
      this.formatDescription(this.distance)
    }
    //all'interno del ranking
    else if (now >= dt_start && now <= dt_end) {
      this.distance = dt_end - now
      this.statusDescription = "nel periodo delle votazioni"
      this.longDescription = "PARTECIPA"
      this.period = rankingperiods.during;
      // green
      //this.formatDescription(distance)
    }
    //prima della prossima ranking
    else if (now > dt_end && now <= dtnext_start) {
      this.statusDescription = "tra una votazione e l'altra"
      this.distance = dtnext_start - now
      this.period = rankingperiods.before;
      //red
      this.formatDescription(this.distance)
    }
    // non ci sono più ranking
    else if (now >= dtnext_start || dtnext_start == null) {
      this.statusDescription = "non ci sono più ranking"
      // this.formatDescription()
      this.period = rankingperiods.end;
      this.shortDescription = null
      this.longDescription = null
      this.distance = 0
      clearInterval(this.x);
    }

    //console.log("DATI COMPUTE DATE: ",this.days, " ", this.hours, " ", this.minutes)
    //console.log(this.longDescription)
    // console.log(this.statusDescription)

    this.restituisciParametriAlChiamante()
  }

  formatDescription(distance: any) {
    this.days = Math.floor(distance / (1000 * 60 * 60 * 24)); // trasformazione in DD, HH, MM
    this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    this.seconds = Math.floor((distance % (1000 * 60)) / 1000);  // secondi: solo per i test

    // distanza: almeno un giorno
    if (this.days >= 1) {
      this.shortDescription = this.days.toString() + 'g'
      this.longDescription = this.days.toString() + 'g'
      if (this.hours != 0) this.longDescription = this.longDescription + ' ' + this.hours.toString() + 'h'
      return
    }
    // if(this.days<1){
    //   this.shortDescription = (24+this.hours).toString()+'h'
    //   this.longDescription = this.days.toString()+'g ' + this.hours.toString()+'h' + this.minutes.toString()+'m'
    // }
    // distanza: meno di un giorno
    if (this.hours <= 23) {
      this.shortDescription = this.hours.toString() + 'h'
      this.longDescription = this.hours.toString() + 'h ' + this.minutes.toString() + 'm'
    }
    // if (this.hours >=1){
    //   this.shortDescription = this.hours.toString()+'h ' + this.minutes.toString()+'m'
    //   this.longDescription = this.hours.toString()+'h ' + this.minutes.toString()+'m'
    //   return
    // }
    if (this.hours == 0) {
      this.shortDescription = this.minutes.toString() + 'm'
      this.longDescription = this.minutes.toString() + 'm'
      return
    }

  }

  formatDateShort(dateAsPromise): string {
    const data = this.ios ? new Date(parseISO(dateAsPromise)) : new Date(dateAsPromise);
    let days = ['domenica', 'lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato']
    let mouths = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre']
    let day_n = data.getDate().toString()
    let day_descr = days[data.getDay()]
    let mouth_descr = mouths[data.getMonth()]
    let hours = data.getHours().toString()
    let minutes = data.getMinutes().toString()
    let dataFormattata = day_descr + ' ' + day_n + ' ' + mouth_descr
    return dataFormattata
  }

  restituisciParametriAlChiamante() {
    if (this.distance <= 0) {
      this.longDescription = null;
      this.period = this.valuesperiod.end
    }

    let params = {
      longDescription: this.longDescription,
      phasecompute: this.phasecompute
    }

    this.parametriAlChiamante.emit(params)
  }

}
