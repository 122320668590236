import { RankingHeaderComponent } from './../pages/ranking-header/ranking-header.component';
import { TBPlayerCardComponent } from './turbo-ballots-runner/tb-player-card/tb-player-card.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MainHeaderComponent } from './main-header/main-header.component';
import { CountdownMinuteComponent } from './countdown-minute/countdown-minute.component';
import { RankingHeaderPageModule } from '../pages/ranking-header/ranking-header.module';


@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    RankingHeaderPageModule   
  ],
  declarations: [    
    MainHeaderComponent,
    TBPlayerCardComponent, 
    CountdownMinuteComponent
  ],
  exports: [    
    MainHeaderComponent,
    TBPlayerCardComponent, 
    CountdownMinuteComponent
  ]
})
export class CommonComponentsModule { }
