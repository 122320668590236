import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  catalogItems: Array<any> = null;

  constructor(
    private dataService: DataService,
    private cacheService: CacheService
  ) { }

  async getCatalogItems(): Promise<any> {
    if (!!this.catalogItems) {
      return this.catalogItems;
    }
    const catalogItemsHttpRequest = this.dataService.httpGet('/catalog/list');
    const catalogItemsResponse = await this.cacheService.getData('/catalog/list', catalogItemsHttpRequest);
    this.catalogItems = catalogItemsResponse.data;
    return this.catalogItems;
  }

  async buyCatalogItem(catalogItemId, userId, paymentType): Promise<any> {
    return await this.dataService.httpPost('/catalog/purchase', {
      user_id: userId,
      payment_type: paymentType,
      catalog_item_id: catalogItemId
    });
  }

  async addPointsToUser(data: any): Promise<any> {
    return await this.dataService.httpPost('/catalog/points', data);
  }

}
