import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tb-player-card',
  templateUrl: './tb-player-card.component.html',
  styleUrls: ['./tb-player-card.component.scss'],
})
export class TBPlayerCardComponent implements OnInit {

  @Input() player;
  @Input() ballot;
  @Input() cardSize;

  // Internal state
  cardStyle: any = {};
  picture;
  nextMatch;
  cardSelectedTmp: boolean = false;


  constructor() { }

  ngOnInit() {
    this.picture = this.getPlayerPicture();
    this.nextMatch = this.getPlayerNextMatch();
    this.setStyle(this.picture);
  }

  setStyle(backgroundImage) {
    this.cardStyle = {
      background: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center center'
    };
  }

  getPlayerPicture() {
    if (!!this.player && !!this.player.avatar_url) {
      // return `${this.player.avatar_url}`;
      return `${environment.baseUrl}${this.player.avatar_url}`;
    }
    return '/assets/images/player-placeholder.png';
  }

  getPlayerNextMatch() {
    if (!!this.player && !!this.player.next_match) {
      const nextMatch = this.player.next_match.toUpperCase();
      const splittedNextMatch = nextMatch.split('-');
      if (splittedNextMatch.length === 2) {
        return splittedNextMatch;
      }
      return nextMatch;
    }
    return null;
  }

  cardClicked() {
    this.cardSelectedTmp = true;
    const t = setTimeout(() => {
      this.cardSelectedTmp = false;
      clearTimeout(t);
    }, 5000);
  }

}
