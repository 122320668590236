import { Injectable } from '@angular/core';
import { AppStorageService } from './app-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(
    private appStorageService: AppStorageService
  ) { }

  async getData(dataKey: string, sourceHttpRequest: Promise<any>, forceSync: boolean = false) {

    // Try to get data from storage
    let data = await this.appStorageService.get(dataKey);

    // If there is already stored data "fire and forget" server request but return current data
    if (!!data && !!forceSync === false) {
      sourceHttpRequest
        .then(async (responseData: any) => {
          if (!!responseData) {
            this.appStorageService.set(dataKey, responseData);
          }
        })
        .catch((reason) => { console.log(reason); });

      return data;
    }

    // There is not already stored data, so call and await reponse from server
    data = await sourceHttpRequest;

    // If server return data, save it to storage
    if (!!data) {
      await this.appStorageService.set(dataKey, data);
    }

    // Return what data we have
    return data;
  }

  async getFromCache(dataKey) {
    const data = await this.appStorageService.get(dataKey);
    return data;
  }

  async deleteData(dataKey) {
    const removeResult = await this.appStorageService.remove(dataKey);
    return removeResult;
  }

}
