<ion-header></ion-header>

<ion-content [fullscreen]="true">
  <br>
  <ion-grid class="ion-align-items-center ion-text-center">
    <ion-row *ngIf="buttonText != 'VOTA'">
        <ion-col size="12" style="vertical-align: middle !important;">
            <div class="heading ">
                <div class="heading-title">{{title}}
                </div>
                <div class="heading-subtitle">{{subtitle}}
                </div>
            </div>
        </ion-col>
    </ion-row>
    <ion-row *ngIf="buttonText === 'VOTA'">
      <ion-col size="12" style="vertical-align: middle !important;">
          <div class="heading ">
              <div class="heading-title">{{title}}
              </div>
              <div class="heading-subtitle">{{subtitle}}
              </div>
          </div>
      </ion-col>
      <ion-col size="12">
        <ion-button class="heading_vote" style="font-size: x-large; color: white; border-style: none;" (click)="beginRanking()"
                    [disabled]="buttonDisabled"
                    color="danger">
                    {{buttonText}}
        </ion-button>
      </ion-col>

  </ion-row>
</ion-grid>

 <br><br>
 <app-countdown (parametriAlChiamante)="riceviParametriDalCountdown($event)"
                [programmaChiamante]="programmaChiamante">
</app-countdown>
<br><br>

<ion-grid class="ion-align-items-center ion-text-center" *ngIf="phasecompute">
  <ion-row>
    <ion-col size="12" style="vertical-align: middle !important;">
      <div class="heading">
        <div class="heading-subtitle">{{phasecompute}}
        </div>
      </div>
    </ion-col>
</ion-row>
</ion-grid>
<br><br>


</ion-content>
