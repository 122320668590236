import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { RankinghelpPage } from '../rankinghelp/rankinghelp.page';

@Component({
  selector: 'app-ranking-header',
  templateUrl: './ranking-header.component.html',
  styleUrls: ['./ranking-header.component.scss'],
})
export class RankingHeaderComponent implements OnInit {

  constructor(private router: Router,private modalController:ModalController,) { }

  ngOnInit() {}

  async openRanking2() {
    this.router.navigate(['/ranking2']);
  }
  openRanking1() {
    this.router.navigate(['/ranking1']);
  }

  async openRanking3() {
    this.router.navigate(['/ranking3']);
  }

  async openRankinghelp_old() {
    this.router.navigate(['/rankinghelp']);
  }

  async closeModal() {
    await this.modalController.dismiss()
  }

  async openRankinghelp() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: RankinghelpPage,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }


}
