import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {
    path: 'splash',
    loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'prevent-app-use/:reason',
    loadChildren: () => import('./pages/prevent-app-use/prevent-app-use.module').then( m => m.OffLinePageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./pages/logout/logout.module').then( m => m.LogoutPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'home/:ballotToReveal',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'my-ballots/:id',
    loadChildren: () => import('./pages/my-ballots/my-ballots.module').then( m => m.MyBallotsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'my-ballots',
    loadChildren: () => import('./pages/my-ballots/my-ballots.module').then( m => m.MyBallotsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'ranking1',
    loadChildren: () => import('./pages/ranking1/ranking1.module').then( m => m.Ranking1PageModule), canActivate: [AuthGuardService]
  },
  {
    path: 'ranking2',
    loadChildren: () => import('./pages/ranking2/ranking2.module').then( m => m.Ranking2PageModule), canActivate: [AuthGuardService]
  },
  {
    path: 'ranking3',
    loadChildren: () => import('./pages/ranking3/ranking3.module').then( m => m.Ranking3PageModule), canActivate: [AuthGuardService]
  },
  {
    path: 'rankinghelp',
    loadChildren: () => import('./pages/rankinghelp/rankinghelp.module').then( m => m.RankinghelpPageModule), canActivate: [AuthGuardService]
  },
  {
    path: 'rankingballots',
    loadChildren: () => import('./pages/rankingballots/rankingballots.module').then( m => m.RankingballotsPageModule), canActivate: [AuthGuardService]
  },
  // {
  //   path: 'rankingtest',
  //   loadChildren: () => import('./pages/rankingtest/rankingtest.module').then( m => m.RankingtestPageModule), canActivate: [AuthGuardService]
  // } ,
  // {
  //   path: 'rankingparams',
  //   loadChildren: () => import('./pages/rankingparams/rankingparams.module').then( m => m.RankingparamsPageModule), canActivate: [AuthGuardService]
  // },
  {
    path: 'catalog',
    loadChildren: () => import('./pages/catalog/catalog.module').then( m => m.CatalogPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'insert/:type',
    loadChildren: () => import('./pages/insert/insert.module').then( m => m.InsertPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'legals',
    loadChildren: () => import('./pages/legals/legals.module').then( m => m.LegalsPageModule)
  },
  {
    path: 'tests',
    loadChildren: () => import('./pages/tests/tests.module').then( m => m.TestsPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
