import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { BehaviorSubject } from 'rxjs';
import { MaintenanceService } from './maintenance.service';
import { AppStorageService } from './app-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  ABBONAMENTO: number = 30;

  // Observables
  loggedUserObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private dataService: DataService,
    private maintenanceService: MaintenanceService,
    private appStorageService: AppStorageService
  ) {
    this.appStorageService.get('userData').then((userData) => {
      this.loggedUserObservable.next(userData);
    });
  }

  async login(credentials: any): Promise<any> {
    try {
      // await this.maintenanceService.clearCache();
      const response = await this.dataService.httpPost('/auth/login', credentials);

      // Save user data in local storage
      if (!!response.error === false) {
        await this.setLoggedUser(response.data.user, response.data.token);
      }
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async loginAsGuest(): Promise<any> {
    try {
      await this.maintenanceService.clearCacheAndRefreshData();

      const response = await this.dataService.httpGet('/auth/login-guest');
      // Save user data in local storage
      if (!!response.error === false) {
        await this.setLoggedUser(response.data.user, response.data.token);
      }
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async logout() {
    try {
      const logoutResponse = await this.dataService.httpPost('/auth/logout');
      if (logoutResponse === false || !!logoutResponse.error) { return false; }

      await this.setLoggedUser(null, null);
      await this.maintenanceService.clearCacheAndRefreshData(true);
      return true;

    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async deleteAccount(userId: number) {
    console.log({
      userId
    });
    try {
      const deleteResponse = await this.dataService.httpDelete(`/auth/delete-account/${userId}`);
      if (deleteResponse === false || !!deleteResponse.error || deleteResponse.message === 'Unauthenticated.') { return false; }
      await this.setLoggedUser(null, null);
      this.appStorageService.clear();
      return true;

    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async register(credentials: any): Promise<any> {
    return new Promise(async (resolve) => {
      this.dataService.httpPost('/auth/register', credentials)
        .then(async (response) => {
          if (!!response.error) {
            return resolve(response);
          }
          await this.maintenanceService.clearCacheAndRefreshData();
          await this.setLoggedUser(response.data.user, response.data.token);
          return resolve(response);
        })
        .catch((error) => {
          console.log(error);
          return resolve(error.error);
        });
    });
  }

  async updateUser(userData) {
    try {
      const response = await this.dataService.httpPost('/user/update', userData);
      if (!!response === false || response.error === true) {
        return false;
      }
      // Update local copy of user
      await this.maintenanceService.clearCacheAndRefreshData();
      await this.setLoggedUser(response.user);
      return true;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async resetPassword(email: any) {
    try {
      const response = await this.dataService.httpPost('/auth/sendresetlinkemail', { email });
      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  async setLoggedUser(user, accessToken = false) {
    await this.appStorageService.set('userData', user);
    //console.log("accessToken ricevuto: "+accessToken);
    if (accessToken !== false) {
      await this.appStorageService.set('accessToken', accessToken);
      //console.log("accessToken set: "+accessToken)
    }
    this.loggedUserObservable.next(user);
  }

  async getLoggedUser(updateFromServer = false) {
    // IF updateFromServer === true get fresh user data from server
    if (updateFromServer === true) {
      const freshUser = await this.dataService.httpGet('/user/me');
      this.setLoggedUser(freshUser); // Fire and forget to store locally an update copy
      return freshUser;
    }

    // Just get local stored copy of user
    const user = await this.appStorageService.get('userData');
    return user;
  }

  async refreshLoggedUser(updatedUser) {
    // const me = await this.me(true);
    this.loggedUserObservable.next(updatedUser);
    return await this.setLoggedUser(updatedUser);
  }

  isUserGuest() {
    const loggedUser = this.loggedUserObservable.getValue();
    if (!!loggedUser === false) { return null; }
    return loggedUser.user_type === 'guest';
  }

  isUserPro() {
    const loggedUser = this.loggedUserObservable.getValue();
    if (!!loggedUser === false) { return null; }
    return loggedUser.user_type === 'pro';
  }

  async addPointsToUser(points) {
    const loggedUser = this.loggedUserObservable.getValue();
    const userId = loggedUser.id;
    const response = await this.dataService.httpPost(`/user/add-points`, { userId, points });
    console.log("ADDPOINTS:"+JSON.stringify(response))
    if (!!response === false || response.error) { return false; }
    await this.setLoggedUser(response.user);
    return true;
  }

  addYear(inputDate: Date): Date{
    var year  = inputDate.getFullYear();
    var month = inputDate.getMonth();
    var day   = inputDate.getDate();
    var hour   = inputDate.getHours();
    var minute   = inputDate.getMinutes();
    var second   = inputDate.getSeconds();
    //  var date  = new Date(year + this.ABBONAMENTO, month, day,hour,minute);
    var date  = new Date(year, month, day,hour,minute+this.ABBONAMENTO,second);
    return date
  }

  removeYear(inputDate: Date): Date{
    var year  = inputDate.getFullYear();
    var month = inputDate.getMonth();
    var day   = inputDate.getDate();
    var hour   = inputDate.getHours();
    var minute   = inputDate.getMinutes();
    var second   = inputDate.getSeconds();
    // var date  = new Date(year + this.ABBONAMENTO, month, day,hour,minute);
    var date  = new Date(year, month, day,hour,minute-this.ABBONAMENTO,second);
    return date
  }



}
