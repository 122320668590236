<ion-grid class="ion-no-padding toolbar-bottom" id="custom-grid">
  <ion-row>

    <ion-col size="2">
      <ion-button class="ion-no-margin gradient__primary-secondary" expand="full" (click)="openRankinghelp()">
        <ion-label>?</ion-label>
      </ion-button>
    </ion-col>

    <ion-col size="4">
      <ion-button class="ion-no-margin gradient__primary-secondary" expand="full" (click)="openRanking1()">
        <ion-label>
          GIOCA
        </ion-label>
      </ion-button>
    </ion-col>

    <ion-col size="4">
      <ion-button class="ion-no-margin gradient__primary-secondary" expand="full" (click)="openRanking2()">
        <ion-label>
          CLASSIFICA
        </ion-label>
      </ion-button>
    </ion-col>

    <ion-col size="4">
      <ion-button class="ion-no-margin gradient__primary-secondary" expand="full" (click)="openRanking3()">
        <ion-label>
          PATENTINO
        </ion-label>
      </ion-button>
    </ion-col>

  </ion-row>
</ion-grid>
