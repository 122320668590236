<ion-content [fullscreen]="true">
<div id="sfondoAnimato" *ngIf="!!this.rankingParams">
<!-- <br> -->
<ion-grid>
  <ion-row class="ion-align-items-center ion-text-center heading">
    <ion-col size="3">
      <img src="/assets/images/ranking-trofeo.png" class="ranking-icon" />
    </ion-col>
    <ion-col size="9" class="ion-align-items-center ion-text-center">
          <div class=" ion-margin-top">
              <div class="heading-title">
                <!-- <ion-text i18n>Sei in gara - VOTA</ion-text> -->
                <ion-text i18n>{{rankingParams.ranking_title}}</ion-text>
              </div>
              <div class="heading-subtitle">
                  <!-- <ion-text i18n>Chi schiereresti titolare ? </ion-text> -->
                  <ion-text i18n>{{rankingParams.ranking_subtitle}} </ion-text>
              </div>
          </div>
        </ion-col>
    </ion-row>
</ion-grid>

<!--  -----------------------------------------------
             C O U N T D O W N
  ----------------------------------------------  -->
  <app-countdown (parametriAlChiamante)="riceviParametriDalCountdown($event)"
                [programmaChiamante]="programmaChiamante">
  </app-countdown>



<!--  -----------------------------------------------
      V O T A Z I O N I    D E L    R A N K I N G
  ----------------------------------------------  -->
  <app-tb-ranking (closeRankingballotsPage)="closeRankingballotsPage($event)">
  </app-tb-ranking>

</div>
</ion-content>
