import { Router } from '@angular/router';
import { RankingService } from './../../services/ranking.service';
import { IrankingParams, IrankingUser, IrankingDays} from './../../models/ranking';
import { Component, OnInit, OnChanges } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Ranking1Page } from '../ranking1/ranking1.page';
import { Ranking2Page } from '../ranking2/ranking2.page';
import { RankinghelpPage } from '../rankinghelp/rankinghelp.page';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-ranking3',
  templateUrl: './ranking3.page.html',
  styleUrls: ['./ranking3.page.scss'],
})
export class Ranking3Page implements OnInit, OnChanges {
  selectedrkanag_id: number        // ngModel : il ranking selezionato
  dateRanking_start: string         // ngModel : inizio e fine ranking
  dateRanking_end: string
  
  rankingParams:IrankingParams      // parametri
  myRanking:IrankingUser[]          // i miei punteggi settimanali
  rankingDays:IrankingDays[]        // le giornate del ranking       
  myCurrentRanking:IrankingUser     // i miei punteggi, per il patentino
  // stati della pagina
  status_ShowSelection: boolean         // si sta selezionando
  status_ShowRanking: boolean           // si sta visionando il ranking
  status_ShowLicence: boolean           // si sta visionando il patentino
  status_ShowCountDown: boolean         // si sta collaudando il countdown
  buzz: HTMLIonLoadingElement;
  loading=false;
  
  constructor(private modalController:ModalController,
              private userService: UserService,
              private rankingService:RankingService,
              private loadingController: LoadingController,
              private router:Router) {
                
              }


  async startbuzz() {
    this.buzz= await this.loadingController.create({
      spinner: 'bubbles',
      translucent: true,
    })
    this.buzz.present();
  }

  async ngOnInit() {
    let loggedUser_id = 0
    const loggedUser = await this.userService.loggedUserObservable.getValue();
    if (!!loggedUser) {
      loggedUser_id = loggedUser.id
    }

    // parametri
    const appo = await this.rankingService.rkpSelect('rkxparams_last', 0, 0)    
    if ((appo!=null) && (appo?.length>0)) this.startbuzz(); 
    if (appo.length>0) {
      this.rankingParams = appo[0];  
      this.dateRanking_start = this.rankingParams.dt_start
      this.dateRanking_end = this.rankingParams.dt_end  
    } else  {
      this.rankingParams=null;
      this.loading=true;
      return;
    }

   
    const appo1 = await this.rankingService.rkpSelect('rkxusers', 0, loggedUser_id)    
    this.myRanking = appo1;  
    
    const appo2 = await this.rankingService.rkpSelect('rkxanag', 0, 0)
    this.rankingDays = appo2    
    this.myCurrentRanking = this.myRanking[0]                         // i punteggi per il patentino
    this.activateNewStatus("ShowLicence")
    if (this.buzz!=null) {
      this.buzz=null;
      this.loading=true;
      this.loadingController.dismiss();
  
    }
  }
  
  ngOnChanges() {
  }


  /* 
      ----------------------------------------------------------------------
               OPZIONI DELLA PAGINA
      ----------------------------------------------------------------------
  */
 activateNewStatus(newStatus:string){
    this.status_ShowLicence = false
    //this.status_ShowLicenceDownload = false
    this.status_ShowSelection = false
    this.status_ShowRanking = false
    this.status_ShowCountDown = false
    switch (newStatus) {
      case 'ShowLicence':
        this.status_ShowLicence = true
        break;
      case 'ShowSelection':
        this.status_ShowSelection = true
        break;
      case 'ShowRanking':
        this.status_ShowRanking = true
        break;

      default:
        this.status_ShowLicence = true
        alert("Ranking3Page/activateStatus() - " + newStatus + "*** PARAMETRO INVALIDO")
        break;
    }
  }

 exitFromSelectionStatus(){
    this.activateNewStatus('ShowLicence')
  }


  /* 
      ----------------------------------------------------------------------
       SOSTITUISCE IL SOTTOTITOLO CON QUELLO RICEVUTO DAL COMPONENTE
      ----------------------------------------------------------------------
  */
  riceviParametriDalPatentino($event:any){
    this.rankingParams.n_day_withwinners = $event
  }

  /* 
      ----------------------------------------------------------------------
               CHIUSURA - APERTURA DELLE PAGINE MODALI
      ----------------------------------------------------------------------
  */

  openRankinghelp_old(){
    this.router.navigate(['/rankinghelp']);
  }
  async openRankinghelp() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: RankinghelpPage,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
      //cssClass: 'vote-modal'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  openRanking1(){
    this.router.navigate(['/ranking1']);
  }
  async openRanking1_old() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: Ranking1Page,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
      //cssClass: 'vote-modal'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  async openRanking2() {
    // this.closeModal()
    // const modal = await this.modalController.create({
    //   component: Ranking2Page,
    //   componentProps: {
    //     modalMode: true,
    //     modalController: this.modalController
    //   }
    //   //cssClass: 'vote-modal'
    // });
    // await modal.present();
    // const { data } = await modal.onWillDismiss();
    this.router.navigate(['/ranking2']);
  }

  async closeModal(){
    await this.modalController.dismiss()
  }


}
