import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CacheService } from './cache.service';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from '../helpers/helpers';
import { AppStorageService } from './app-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BallotsService {

  votedBallotsCount: number;

  onNVotesCountNotification: EventEmitter<number> = new EventEmitter<number>();
  myBallotsObservable: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private dataService: DataService,
    private cacheService: CacheService,
    private userService: UserService,
    private appStorageService: AppStorageService
  ) {
    this.initialize();
  }

  async initialize() {
    this.votedBallotsCount = await this.getVotedBallotsCount();
  }

  async getBallot(id: number) {
    return this.dataService.httpGet(`/ballots/ballot/${id}`);
  }

  async updateBallot(ballot: any) {
    console.log('ballot -> ', ballot);
    const params = ballot;
    return this.dataService.httpPatch(`/ballots/ballot`,params);
  }

  async getTurboBallot(userId, forceSync = false) {
    const params = { user_id: userId };
    const turboBallotHttpRequest = this.dataService.httpGet('/ballots/turbo-ballot', params);
    const turboBallotResponse = await this.cacheService.getData('/ballots/turbo-ballot' + JSON.stringify(params), turboBallotHttpRequest, forceSync);
    return turboBallotResponse.turbo_ballot;
  }

  async voteBallot(voteData: any): Promise<any> {
    console.log('voteData -> ', voteData);
    const params = Object.assign({}, voteData, { dont_save_votes: environment.dev_disableSavingVotes });
    const voteResponse = await this.dataService.httpPost('/ballots/vote', params);
    this.incrementVotedBallotsCount();
    return voteResponse;
  }

  async incrementVotedBallotsCount() {
    this.votedBallotsCount++;
    await this.appStorageService.set(environment.VOTED_BALLOTS_COUNT_KEY, this.votedBallotsCount.toString());
    if ((this.votedBallotsCount % environment.adsSettings.notificateVotesCountEveryN) === 0) {
      this.onNVotesCountNotification.emit(this.votedBallotsCount);
    }
  }

  async getVotedBallotsCount() {
    const storedVotedBallotsCount = await this.appStorageService.get(environment.VOTED_BALLOTS_COUNT_KEY);
    if (!!storedVotedBallotsCount === false) {
      await this.appStorageService.set(environment.VOTED_BALLOTS_COUNT_KEY, '0');
      return 0;
    } else {
      return parseInt(storedVotedBallotsCount, 10);
    }
  }

  async refreshMyBallots(forceSync?: boolean): Promise<any> {
    const myBallots = await this.getMyBallots(forceSync);
    this.myBallotsObservable.next(myBallots);
  }

  async getMyBallots(forceSync?: boolean): Promise<any> {
    const user = await this.userService.loggedUserObservable.getValue();
    const  params = { user_id: user.id };
    const myBallotsHttpRequest = this.dataService.httpGet('/ballots/my-ballots', params);
    const myBallotsResponse = await this.cacheService.getData('/ballots/my-ballots' + JSON.stringify(params), myBallotsHttpRequest, forceSync);
    return myBallotsResponse.ballots;
  }

  async getBallotsByRankingId(rankingId: number, forceSync?: boolean) {
    const params: any = { ranking_id: rankingId };
    const httpRequest = this.dataService.httpGet('/ballots/get-by-ranking-id', params);
    const response = await this.cacheService.getData('/ballots/get-by-ranking-id' + JSON.stringify(params), httpRequest, forceSync);
    return response.data;
  }

  async deleteBallot(ballotId: number, _refreshMyBallots: boolean): Promise<any> {
    const user = await this.userService.loggedUserObservable.getValue();
    const params = { user_id: user.id, ballot_id: ballotId };
    const deleteBallotResponse = await this.dataService.httpPost('/ballots/delete-ballot', params);

    // Refresh local data, when required
    if (_refreshMyBallots == true) {
      await this.refreshMyBallots(true);
    }
    return deleteBallotResponse;
  }

  async createExchangeBallot(params: any): Promise<any> {
    const user = await this.userService.loggedUserObservable.getValue();
    const paramsComplete = Object.assign(params, { user_id: user.id });
    const ballotResponse = await this.dataService.httpPost('/ballots/create-exhange', JSON.stringify(paramsComplete));

    // Refresh local data
    await this.refreshMyBallots(true);

    return ballotResponse;
  }

  async createFormationBallot(params: any): Promise<any> {
    const user = await this.userService.loggedUserObservable.getValue();
    const paramsComplete = Object.assign(params, { user_id: user.id });
    const ballotResponse = await this.dataService.httpPost('/ballots/create-formation', JSON.stringify(paramsComplete));

    // Refresh local data
    await this.refreshMyBallots(true);

    return ballotResponse;
  }

  async createPurchaseBallot(params: any): Promise<any> {
    const user = await this.userService.loggedUserObservable.getValue();
    const paramsComplete = Object.assign(params, { user_id: user.id });
    const ballotResponse = await this.dataService.httpPost('/ballots/create-purchase', JSON.stringify(paramsComplete));

    // Refresh local data
    await this.refreshMyBallots(true);

    return ballotResponse;
  }

  improveBallotsList(ballots) {
    if (!!ballots === false) { return null; }
    return ballots.map(b => {
      const bImproved = Object.assign(b, {
        matrixArray: Helpers.extractBallotMatrixToArray(b.matrix)
      });
      return bImproved;
    });
  }

}
