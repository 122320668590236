import { Router } from '@angular/router';
import { RankingService } from './../../services/ranking.service';
import { IrankingParams } from './../../models/ranking';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Ranking1Page } from '../ranking1/ranking1.page';
import { ToastService } from 'src/app/services/toast.service';
import { RankinghelpPage } from '../rankinghelp/rankinghelp.page';

@Component({
  selector: 'app-rankingballots',
  templateUrl: './rankingballots.page.html',
  styleUrls: ['./rankingballots.page.scss'],
})
export class RankingballotsPage implements OnInit {
  programmaChiamante : string = "RankingballotsPage"

  rankingParams:IrankingParams
  constructor(private modalController:ModalController,
      private toastService:ToastService,
      private rankingService:RankingService,
      private router: Router
      ){}
    
  async ngOnInit() {
    const appo = await this.rankingService.rkpSelect('rkxparams_last', 0, 0)    
    this.rankingParams = appo[0]; 
  }

  closeRankingballotsPage(event){
    this.returnToRanking1()
  }

  async openRankinghelp() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: RankinghelpPage,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
      //cssClass: 'vote-modal'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

 //-------------------------------------------------  
 riceviParametriDalCountdown($event:any){
  let params = $event
  let title=params.ranking1Page_title
  let subtitle=params.ranking1Page_subtitle
  let flCloseRankingBallotsPage = params.flCloseRankingBallotsPage
  if (flCloseRankingBallotsPage==true){
    this.toastService.presentSuccessToast("Il perido per votare il Ranging è terminato")
    this.returnToRanking1()
  }
  // alert(JSON.stringify(params))
} 

returnToRanking1(){
  this.router.navigate(['/ranking1']);
}

returnToRanking2(){
  this.router.navigate(['/ranking2']);
}
//-------------------------------------------------  
  // chiusure/aperture pagine modali
  async returnToRanking1_old() {
    this.closeModal()
    const modal = await this.modalController.create({
      component: Ranking1Page,
      componentProps: {
        modalMode: true,
        modalController: this.modalController
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
  }

  async closeModal(){
    await this.modalController.dismiss()
  }

}
