import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from "@angular/core";
import { IrankingParams, IrankingBallotsVoted} from './../../models/ranking';
import { UserService } from 'src/app/services/user.service'
import { RankingService } from 'src/app/services/ranking.service';
import { parseISO } from "date-fns";
import { Platform } from "@ionic/angular";

/*
  -----------------------------------------------------------------------------------
    COUNTDOWN,  per le pagine :   Ranking1Page, per la presentazione del raking
                                  RankingballotsPage, preludio alle votazioni
              INPUT:rankingParams, con:
                      dt_start:         l'inizio dellle votazioni per il ranking in essere
                      dt_end:           la fine delle votazioni per il ranking in essere
                      dtnesxt_start:    l'inizio del ranking successivo
                    rankingVotedBallots, con
                      user_id:          utente di login
	                    voted_ballots:    numero ballottaggi effettivamente votati
	                    n_ballots:        ballottaggi di quel ranking

              SERVIZIO
                    firstDescription:   prima riga di desctizione del countdown
                    secondDescription:  seconda riga  ... ...
                    thirdDescription:   SPECIFICA PER IL COUNTDOWN DELLE VOTAZIONI
                    flCloseRankingBallotsPage: true=votazione conclusa, chiudi la pagina
                    --------------------------------------------------------------
                    to_beginRanking:    ZERO, oppure il tempo di attesa al prossimo ranking
                    to_endRanking:      ZERO, oppure il tempo ancora disponibile per le votazioni
                    flVotingPeriod:     true se siamo nel periodo delle votazioni
                    flShowCountdown:    true per mostrare la riga del countdown
                    flVotationCompleted: true quando sono stati votati tutti i ballottaggi

              OUTPUT
                    flButtonVote:           true: abilita il pulsante per le votazioni
                    ranking1Page_title:     il titolo della pagina Ranking1Page
                    ranking1Page_subtitle:  il sottotitolo della pagina Ranking1Page
  -----------------------------------------------------------------------------------
*/

enum rankingperiods {
  before= 0,
  during = 1,
  after = 2,
  end =3
}
@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})

export class CountdownComponent implements OnInit, OnChanges{
  @Input() programmaChiamante : string = "Ranking1Page"
  @Output() parametriAlChiamante = new EventEmitter<any>()
  rankingParams:IrankingParams              // parametri del ranking
  rankingVotedBallots:IrankingBallotsVoted  // numero dei ballottaggi votati
  days : number                             // tempi di attesa
  hours: number
  minutes: number
  seconds: number
  flVotingPeriod: boolean  = true           // stati
  flButtonVote: boolean = true
  flShowCountdown: boolean = true
  flVotationCompleted: boolean = true
  ranking1Page_title: string                // messaggi
  ranking1Page_subtitle: string
  firstDescription: string
  secondDescription: string
  thirdDescription: string
  flCloseRankingBallotsPage: boolean = false
  logged_User_id: number
  period: rankingperiods | null;
  valuesperiod = rankingperiods;
  phasecompute: any=null;
  lastcomputed: IrankingParams | null;
  ios: any;

  constructor(private userService: UserService,
              private rankingService:RankingService,
                private platform: Platform){
                  if (this.platform.is('ios')) {
                    this.ios=true;
                  }
              }

  ngOnChanges(){
    let x = this.programmaChiamante
   // alert("CountdownComponent/ngOnChanges() - programmaChiamante:" + this.programmaChiamante)
  }


  async ngOnInit() {
    this.flShowCountdown = false
    // -----------------------------------------------------------------------
    //  VERIFICA DELL'UTENTE : se 'guest', esce
    // -----------------------------------------------------------------------
    const loggedUser = await this.userService.loggedUserObservable.getValue();
    let user_id=0
    let user_type='guest'
    if (!!loggedUser) {
      this.logged_User_id = loggedUser.id
      user_type = loggedUser.user_type
      }
      //user_type='guest'
      if (user_type=='guest'){
        this.ranking1Page_title = 'Partecipa al ranking'
        this.ranking1Page_subtitle = '(solo per utenti registrati)'
        this.flButtonVote = false
        this.flVotingPeriod = false
        this.flShowCountdown = false
        this.flVotationCompleted = false
        this.firstDescription = 'Per partecipare al Ranking: fai logout e registrati!'
        this.secondDescription = "Cos'è il Ranking? Clicca il punto di domanda in alto!"
        this.thirdDescription = null
        this.flCloseRankingBallotsPage = false
        this.restituisciParametriAlChiamante()
        return
      }
    // -----------------------------------------------------------------------
    //  estrae i parametri del data base
    //      verifica lo stato della votazione
    // -----------------------------------------------------------------------
    const appo = await this.rankingService.rkpSelect('rkanag', 0, 0)        // parametri
    let lastcompute: any;
    appo.forEach((element) => {
      let start= this.ios ? new Date(parseISO(element.dt_start)): new Date(element.dt_start);
      let end= this.ios ? new Date(parseISO(element.dt_end)): new Date(element.dt_end);
      let attuale=new Date();
      let compute = this.ios ? new Date(parseISO(element.dt_compute)): new Date(element.dt_compute);
      if ((attuale<=end) && (this.rankingParams==null) ) {
        this.rankingParams=element;
        this.rankingParams=element;
        if (attuale<start) {
          if (!!this.lastcomputed) {
            if (attuale<=(new Date(parseISO(this.lastcomputed.dt_compute)))) {
              console.log("I risultati dell'ultima settimana saranno pubblicati il ",this.formatDateShort(this.lastcomputed.dt_compute));
              this.phasecompute="I risultati dell'ultima settimana saranno pubblicati  "+this.formatDateShort(this.lastcomputed.dt_compute)
            }
          }
        }
      }
      this.lastcomputed=element;
    });
    console.log("rankings: ",appo);
    console.log("rankingParams: ",this.rankingParams);
    if (this.rankingParams==null) {
      this.ranking1Page_title = 'Ranking'
      this.ranking1Page_subtitle = 'Stagione finita'
      this.flButtonVote = false
      this.flVotingPeriod = false
      this.flShowCountdown = false
      this.flVotationCompleted = false
      this.firstDescription = 'La stagione è finita. '
      let attuale=new Date();

      console.log(this.lastcomputed)
      let compute = this.ios ? new Date(parseISO(this.lastcomputed.dt_compute)): new Date(this.lastcomputed.dt_compute);

        if (!!this.lastcomputed) {
          if (attuale<=(new Date(parseISO(this.lastcomputed.dt_compute)))) {
              console.log("I risultati dell'ultima settimana saranno pubblicati il ",this.formatDateShort(this.lastcomputed.dt_compute));
              this.firstDescription=this.firstDescription+"I risultati dell'ultima settimana saranno pubblicati "+this.formatDateShort(this.lastcomputed.dt_compute)
            }
          }

      this.secondDescription = "Attendi la prossima stagione! Ti aspettiamo per continuare il gioco!"
      this.thirdDescription = null
      this.flCloseRankingBallotsPage = false
      this.restituisciParametriAlChiamante()
      return;
    }
    this.rankingParams.rkanag_id=this.rankingParams.id;
    //console.log("P: ",this.rankingParams);
    //this.rankingParams = appo[0];
    const appo1 = await this.rankingService.rkpSelect('rkxballots_voted', this.rankingParams.rkanag_id, this.logged_User_id)    // ballottaggi votati
      this.rankingVotedBallots = appo1[0];
      if (this.rankingVotedBallots==null){
        this.rankingVotedBallots = { // indefinito: rigenera l'oggetto
          rkanag_id: this.rankingParams.rkanag_id,
          user_id: user_id,
          voted_ballots:0,			     // numero ballottaggi effettivamente votati
          n_ballots: this.rankingParams.ballots_n
        }
      }
      if (this.rankingVotedBallots.voted_ballots==this.rankingVotedBallots.n_ballots){
        this.flVotationCompleted = true      // sono stati votati tutti i ballottaggi
      } else{
        this.flVotationCompleted = false     // la votazione è incompleta
      }

    this.computePeriods()
  }
  computePeriods(){
    let dt_computeFormtted=this.formatDate(this.rankingParams.dt_compute) // formattazione per un messaggio informativo
    let dt_start = this.ios ? (new Date(parseISO(this.rankingParams.dt_start)).getTime()) : Date.parse(this.rankingParams.dt_start);  // conversione in millisecondi
    let dt_end = this.ios ? (new Date(parseISO(this.rankingParams.dt_end)).getTime()) : Date.parse(this.rankingParams.dt_end);

    let dtnext_start = null;
    if (this.rankingParams.dtnext_start!=null) {
      dtnext_start = this.ios ? (new Date(parseISO(this.rankingParams.dtnext_start)).getTime()) : Date.parse(this.rankingParams.dtnext_start);
    }

    this.days=0
    this.hours=0
    this.minutes=0
    this.seconds=0
    let voted_ballots = this.rankingVotedBallots.voted_ballots
    let n_ballots = this.rankingVotedBallots.n_ballots
    // ---------------------------------------------------------
    //      FORZATURE PER LE SIMULAZIONI
    // let MS_PER_MINUTE = 60000
    // let _now =  new Date().getTime()
    // let dt_start = new Date(_now + 0.3 * MS_PER_MINUTE).getTime()
    // if (this.programmaChiamante!='Ranking1Page'){
    //   dt_start = new Date(_now + 0 * MS_PER_MINUTE).getTime()
    // }
    // let dt_end =  new Date(_now + 30 * MS_PER_MINUTE).getTime()
    // let dtnext_start = new Date(_now + 90 * MS_PER_MINUTE).getTime()
    // this.flVotationCompleted = true
    // ---------------------------------------------------------
    let now = new Date().getTime();
    let flag=1
    let distance = 0  // distanza in millisecondi
    let x = setInterval(()=> {
      if (now<=dt_start){
        // -------------------------------------------------------
        // PRIMA DELL'IMMINENTE VOTAZIONE
        // -------------------------------------------------------
        flag=1
        this.ranking1Page_title = 'In attesa del Ranking'
          this.ranking1Page_subtitle = 'Attendi la fine del countdown'
          this.flButtonVote = false
          this.flShowCountdown = true
          this.firstDescription = 'Prossimo Ranking tra:'
          this.secondDescription = null
          this.thirdDescription = null
          this.period=rankingperiods.before;
          this.flCloseRankingBallotsPage = false
      }
      if (now >=dt_start && now <= dt_end){
        // -------------------------------------------------------
        // NEL PERIODO DELLA VOTAZIONI
        // -------------------------------------------------------
        flag=2
        if (n_ballots==null) n_ballots=0;
        if (this.flVotationCompleted==false){ // votazioni da completare
          this.ranking1Page_title = 'Completa i ballottaggi del Ranking'
          this.ranking1Page_subtitle = 'Vota tutti i ballottaggi di formazione'
          this.flButtonVote = true
          this.flShowCountdown = true
          this.period=rankingperiods.during;
          if (this.rankingVotedBallots.voted_ballots==0) {this.firstDescription = 'Non hai ancora votato nessun ballottaggio.' } else
            {this.firstDescription = 'Hai votato '+voted_ballots.toString()+" ballottaggi."}
          this.secondDescription = 'Completa tutti i ballottaggi di formazione entro la fine del countdown:'
          if (now>dt_end-10000 && now<dt_end){
            this.thirdDescription = 'stiamo chiudendo la pagina delle votazioni'
            //if (now==dt_end){this.flCloseRankingBallotsPage = true
            if (now>dt_end-1000 && now<dt_end){this.flCloseRankingBallotsPage = true
              } else {this.flCloseRankingBallotsPage = false}
          } else {
            this.thirdDescription = null
            this.flCloseRankingBallotsPage = false
          }

        } else { // votazioni completate
          this.ranking1Page_title = 'Hai completato le votazioni del Ranking'
          this.ranking1Page_subtitle = ''
          this.flButtonVote = false
          this.flShowCountdown = false
          this.period=rankingperiods.end;
          this.firstDescription = 'Attendi la pubblicazione dei tuoi risultati.'
          //this.secondDescription = 'I risultati del Ranking saranno disponibili il ' + dt_computeFormtted
          this.secondDescription = 'I risultati del Ranking settimanale sono disponibili da ' +this.formatDateShort(this.rankingParams.dt_compute)
          this.thirdDescription = null
          this.flCloseRankingBallotsPage = false
        }
      }
      if (now>dt_end && now <= dtnext_start ){
          // -------------------------------------------------------
          // TRA UNA VOTAZIONE E L'ALTRA
          // -------------------------------------------------------
          flag=3
          this.ranking1Page_title = 'In attesa del Ranking'
            this.ranking1Page_subtitle = 'Consulta il patentino e le classifiche per le tue statistiche'
            this.flButtonVote = false
            this.flShowCountdown = true
            this.firstDescription = null
            this.period=rankingperiods.after;
            this.secondDescription = 'Prossimo Ranking tra:'
            this.thirdDescription = null
      }
      if ((now>= dtnext_start) && (dtnext_start!=null)){
        flag=4
          // -------------------------------------------------------
          // NON CI SONO PIU' VOTAZIONI
          // -------------------------------------------------------
          this.ranking1Page_title = 'In attesa del Ranking'
          this.ranking1Page_subtitle = null
          this.flButtonVote = false
          this.flShowCountdown = false
          this.firstDescription = 'Al momento non sono previsti ulteriori ranking'
          this.secondDescription = null
          this.thirdDescription = null
          this.flCloseRankingBallotsPage = false
          clearInterval(x);
      }
      //this.restituisciParametriAlChiamante()
      now = new Date().getTime();
      switch(flag) {
        case 1:   // prima della votazione del ranking
          distance=dt_start - now
          break;
        case 2:   // durante la votazione del ranking
          distance=dt_end - now
          break;
        case 3:   // dopo la votazione del ranking, per la prossima giornata del campionato
          distance=dtnext_start - now
          break;
        default:  //  ranking terminati (es: fine campionato)
          distance=0
        }
      this.days = Math.floor(distance / (1000 * 60 * 60 * 24)); // trasformazione in DD, HH, MM, SS
      this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      //if (this.seconds>=0){
        //console.log("DATI COMPUTE DATE: ",this.days, " ", this.hours, " ", this.minutes, + " ",distance, " "+this.rankingParams.dt_start.toString())

        this.restituisciParametriAlChiamante()
      //}
    }, 1000);
}
  restituisciParametriAlChiamante(){

    let params = {
      ranking1Page_title: this.ranking1Page_title,
      ranking1Page_subtitle: this.ranking1Page_subtitle,
      flButtonVote: this.flButtonVote,
      flCloseRankingBallotsPage : this.flCloseRankingBallotsPage,
      redirect: false,
      phasecompute: this.phasecompute
    }
    if (this.seconds<0) { params.redirect = true; }
    this.parametriAlChiamante.emit(params)
  }

  formatDate(dateAsPromise):string{
  const data = this.ios? new Date(parseISO(dateAsPromise)): new Date(dateAsPromise);
    let days=['domenica', 'lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato' ]
    let day_n = data.getDate().toString()
    let day_descr = days[data.getDay()]
    let hours = data.getHours().toString()
    let minutes = data.getMinutes().toString()
    let dataFormattata = day_descr + ' ' + day_n + ", ore "+hours+':'+minutes.padStart(2, '0')
    return dataFormattata
  }

  formatDateShort(dateAsPromise):string{
    const data = this.ios? new Date(parseISO(dateAsPromise)): new Date(dateAsPromise);
      let days=['domenica', 'lunedi', 'martedi', 'mercoledi', 'giovedi', 'venerdi', 'sabato' ]
      let mouths=['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre','ottobre','novembre','dicembre' ]
      let day_n = data.getDate().toString()
      let day_descr = days[data.getDay()]
      let mouth_descr = mouths[data.getMonth()]
      let hours = data.getHours().toString()
      let minutes = data.getMinutes().toString()
      let dataFormattata = day_descr + ' ' + day_n + ' ' + mouth_descr
      return dataFormattata
    }

}
