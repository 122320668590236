<ion-header class="main-header ion-no-border" [ngClass]="{'is-ios': isPlatformIos}" *ngIf="hide===false">

  <ion-toolbar color="primary" class="toolbar-top ion-no-margin ion-no-padding ion-no-border">
    <ion-grid>
      <ion-row>
        <ion-col class="ion-align-self-center ion-text-center" size="2">
          <ion-buttons>
            <ion-menu-button></ion-menu-button>
          </ion-buttons>
        </ion-col>

        <ion-col (click)="openRankingPage()" class="ion-align-self-center ion-text-center" size="3">
          <app-countdown-minute [programmaChiamante]="programmaChiamante"></app-countdown-minute>
        </ion-col>

        <ion-col class="ion-align-self-center ion-text-center" size="4">
          <ion-nav-link (click)="changemenu()">
            <img src="/assets/images/logolight.png" class="logo">
            <span *ngIf="!!comunication" [ngClass]="{ 'blink': blinkStart }">
              <ion-badge  color="danger">1</ion-badge>
            </span>
          </ion-nav-link>
        </ion-col>
        <ion-col class="ion-align-self-center ion-text-center" size="3">
          <ion-label *ngIf="showCredits" class="credits">
            <ion-nav-link [routerLink]="['/catalog']" routerDirection="root">
              <ion-row class="ion-no-padding">
                <ion-col class="ion-align-self-center">
                  <img src="/assets/icons/credits.png" [ngClass]="{'animate__animated animate__rubberBand': animateCreditsIcon}">
                </ion-col>
                <ion-col class="ion-align-self-center">
                  <ion-label>{{ credits }}</ion-label>
                </ion-col>
              </ion-row>
            </ion-nav-link>
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-grid class="ion-no-padding toolbar-bottom" *ngIf="IsNotRanking">
      <ion-row>
        <ion-col class="ion-align-self-center ion-text-center ion-text-uppercase" size="4">
          <ion-button class="ion-no-margin gradient__primary-secondary" [routerLink]="['/insert', 'exchange']" routerDirection="root" expand="full">
            Scambio
          </ion-button>
        </ion-col>
        <ion-col class="ion-align-self-center ion-text-center ion-text-uppercase" size="4">
          <ion-button class="ion-no-margin gradient__primary-secondary" [routerLink]="['/insert', 'formation']" routerDirection="root" expand="full">
            Formazione
          </ion-button>
        </ion-col>
        <ion-col class="ion-align-self-center ion-text-center ion-text-uppercase" size="4">
          <ion-button class="ion-no-margin gradient__primary-secondary" [routerLink]="['/insert', 'purchase']" routerDirection="root" expand="full">
            Asta
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <app-ranking-header *ngIf="!IsNotRanking"></app-ranking-header>

  </ion-toolbar>

</ion-header>
