import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { RankingService } from 'src/app/services/ranking.service';
import { UserService } from 'src/app/services/user.service';
import { IrankingUser} from './../../models/ranking';

@Component({
  selector: 'app-patentino',
  templateUrl: './patentino.component.html',
  styleUrls: ['./patentino.component.scss'],
})
export class PatentinoComponent implements OnInit {
  @Output() parametriAlChiamante = new EventEmitter<any>()
  subtitleForMainPage:string
  userName:string
  user_id:number
  myRanking:IrankingUser
 // rankingParams:IrankingParams  // parametri del ranking
  appo_points_atDay: number     // appoggio per risolvere un bug
  appo_n_atDay: number 
  appo_ballots_ofDay: number
  appo_ballotsOK_ofDay: number
  appo_ballotsWrong_ofDay: number
  appo_points_ofDay: number
  correct: number

  constructor(private userService: UserService,
              private rankingService:RankingService) { }

  async ngOnInit() {
    
    // fantallenatore
    const loggedUser = await this.userService.loggedUserObservable.getValue();
    if (!!loggedUser) {
      this.userName = loggedUser.name
      this.user_id = loggedUser.id
    }
    // punteggi 
        // const appo = await this.rankingService.rkpSelect('rkxparams_last', 0, 0)        // parametri
        // this.rankingParams = appo[0]; 
        const appo1 = await this.rankingService.rkpSelect('rkxusers', 0, this.user_id)  
         
        if (appo1.length==0){     
          this.subtitleForMainPage  = null
          this.appo_n_atDay = null
          this.appo_points_atDay = 0
          this.appo_points_ofDay = 0
          this.appo_ballots_ofDay = 0
          this.appo_ballotsOK_ofDay = 0
          this.appo_ballotsWrong_ofDay = 0
          this.correct=0;
        }  else {
          this.myRanking = appo1[0]; 
          this.subtitleForMainPage  = this.myRanking.descr 
          this.userName = this.myRanking.name
          this.appo_n_atDay = this.myRanking.n
          this.appo_points_atDay = this.myRanking.points_atDay
          this.appo_points_ofDay = this.myRanking.points_ofDay
          this.appo_ballots_ofDay = this.myRanking.ballotsVoted_ofDay
          //TODO
          if (this.appo_ballots_ofDay==null) {
            this.appo_ballots_ofDay=10;
          }
          this.appo_ballotsOK_ofDay = this.myRanking.ballots_full
          this.appo_ballotsWrong_ofDay = this.myRanking.ballotsVoted_ofDay - this.myRanking.ballots_full                
          this.correct=(this.appo_ballotsOK_ofDay/this.appo_ballots_ofDay)*100
        }
        this.restituisciParametriAlChiamante()
  }

  restituisciParametriAlChiamante(){   
    this.parametriAlChiamante.emit(this.subtitleForMainPage)
  }

}
/* 
[
  {"rkanag_id":99,"day":99,"descr":"99 - giornata fittizia","user_id":61040,"name":"Mike 9745682","n":1,"points_ofDay":"6.000","points_atDay":"31.000","present":4,"media":"7.800","ballots_full":6,"ballots_full_atDay":31,"ballotsVoted_ofDay":7},
  {"rkanag_id":3,"day":3,"descr":"3\u00b0 giornata, 11-13\/9","user_id":61040,"name":"Mike 9745682","n":1,"points_ofDay":"8.000","points_atDay":"25.000","present":3,"media":"8.300","ballots_full":8,"ballots_full_atDay":25,"ballotsVoted_ofDay":15},
  {"rkanag_id":2,"day":2,"descr":"II GIORNATA","user_id":61040,"name":"Mike 9745682","n":1,"points_ofDay":"11.000","points_atDay":"17.000","present":2,"media":"8.500","ballots_full":11,"ballots_full_atDay":17,"ballotsVoted_ofDay":15},
  {"rkanag_id":1,"day":1,"descr":"I GIORNATA","user_id":61040,"name":"Mike 9745682","n":1,"points_ofDay":"6.000","points_atDay":"6.000","present":1,"media":"6.000","ballots_full":6,"ballots_full_atDay":38,"ballotsVoted_ofDay":15}
] */
