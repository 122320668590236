<div *ngIf="programmaChiamante === 'MainHeaderComponent'" class="countdown-minute">
	<table>
		<tbody>
			<tr>
				<td class="td-left">
					<img src="/assets/images/ranking-trofeo.png" class="trophy" />
				</td>
				<td class="td-right">
					<small *ngIf="!!shortDescription && period === valuesperiod.before" style="white-space: nowrap;" class="countdown">
						{{ longDescription }}
					</small>

					<span *ngIf="period === valuesperiod.during" class="dot-wrapper">
						<img src="/assets/gif/circle.gif" class="dot-bg" />
						<img src="/assets/images/dotgreen.gif" class="dot" />
					</span>
				</td>
			</tr>
		</tbody>
	</table>
</div>