<div class="tb-player-card__wrapper" [ngClass]="{'size-default': cardSize==='default', 'size-small': cardSize==='small'}">

  <button class="tb-player-card"
    [ngStyle]="cardStyle"
    [ngClass]="{'size-default': cardSize==='default', 'size-small': cardSize==='small', 'selected-tmp': cardSelectedTmp }"
    (click)="cardClicked()"
  >

    <ion-badge class="fanta-media" color="success">
      <ion-text *ngIf="!!player.fanta_media; else nofantamedia;">FM: {{ player.fanta_media }}</ion-text>
      <ng-template #nofantamedia><ion-text>FM: sv</ion-text></ng-template>
    </ion-badge>

    <ion-grid class="gradient__primary-secondary-45">
      <ion-row>
        <ion-col class="name-col ion-align-self-center ion-align-items-center ion-text-center" size="12">
          {{ player.surname }}
        </ion-col>
        <ion-col class="data-col ion-align-self-center ion-align-items-center ion-text-center" size="12">
          <ng-container *ngIf="!!nextMatch && ballot.type==='formation'; then nextMatchTpl else teamCodeTpl"></ng-container>
          <ng-template #teamCodeTpl><ion-text>{{ player.team_code }}</ion-text></ng-template>
          <ng-template #nextMatchTpl><ion-text [ngClass]="{'player-team-code': player.team_code === nextMatch[0]}">{{nextMatch[0]}}</ion-text> - <ion-text  [ngClass]="{'player-team-code': player.team_code === nextMatch[1]}">{{nextMatch[1]}}</ion-text></ng-template>
        </ion-col>
      </ion-row>
    </ion-grid>


  </button>

</div>
