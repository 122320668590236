import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { CacheService } from './cache.service';
import { environment } from 'src/environments/environment';
import { MaintenanceService } from './maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class PlayersService {

  allPlayers;

  constructor(
    private dataService: DataService,
    private cacheService: CacheService,
    private maintenanceService: MaintenanceService
  ) { }

  async getAll(forceSync?) {
    if (!!forceSync) {
      await this.maintenanceService.clearCacheAndRefreshData(true);
    }

    const playersHttpRequest = this.dataService.httpGet('/players/list');
    // Check if we have players locally
    if (!!this.allPlayers && !!forceSync === false) {
      // Fire and forget! Return ready data.
      this.cacheService.getData('/players/list', playersHttpRequest, forceSync).then((allPlayers) => { this.allPlayers = allPlayers; });
      return this.allPlayers;
    } else {
      // There is not ready data or a forceSync was requested. Refresh from server!
      this.allPlayers = await this.cacheService.getData('/players/list', playersHttpRequest, forceSync);
      return this.allPlayers;
    }
  }

  async getByQueryLocal(query: any): Promise<any> {
    if (!!this.allPlayers === false) {
      this.allPlayers = await this.getAll(true);
    }

    if (!!query === false || query.length < environment.minPlayerSearchCharacters) {
      return [];
    }

    if (!!this.allPlayers === false) {
      return this.getByQuery(query);
    }

    const filteredPlayers = this.allPlayers[0].filter((player) => {
      return !!player && player.surname.toLowerCase().startsWith(query.toLowerCase());
    });

    return filteredPlayers;
  }

  async getByQuery(query: any): Promise<any> {
    const params = { key: query };
    const playersHttpRequest = this.dataService.httpGet('/players/query', params);
    const playersResponse = await this.cacheService.getData('/players/query' + JSON.stringify(params), playersHttpRequest);
    return playersResponse;
  }

}
