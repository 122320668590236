<ion-grid>
  <ion-row class="ion-align-items-center ion-text-center heading">
    <ion-col class="ion-align-items-center ion-align-self-center " size="12"
      *ngIf="!!ballotObservable && !!ballots_number">
      <span i18n class="heading-title whiteColor">Completa tutti i ballottaggi: {{ ballots_voted + 1 }} di {{
        ballots_number }}<br>
        <b>FORMAZIONE &nbsp; {{ matrix0 }} SU {{matrix1 }}</b></span>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="!!ballotObservable.value && !!ballotObservable.value.players" style="padding-top: 15px;">
    <ion-col [ngClass]="{'animate__animated animate__bounceInUp': !!animate}"
      *ngFor="let player of ballotObservable.value.players; let playerIndex=index;"
      class="single-cards-selection ion-align-items-center ion-text-center" size="6"
      offset="{{ (playerIndex === 0 && arePlayersOdd) ? 3 : 0 }}">
      <div (click)="toggleSelection(player.id)"
        [ngClass]="{'selected': isSelected(player.id), 'animate__animated animate__pulse': isSelected(player.id) && animateSelected}">
        <app-tb-player-card [player]="player" [ballot]="ballotObservable.value"
          [cardSize]="cardsSize"></app-tb-player-card>
      </div>
    </ion-col>
  </ion-row>


</ion-grid>