


<ion-grid id="ballot-grid" *ngIf="ballotObservable">


  <!-- Ballot Votation -->
  <ion-row [ngClass]="{'animate__animated animate__bounceInUp': !!animate}">
    <ion-col size="12">
      <app-tb-ranking-votes  [ballotObservable]="ballotObservable"
          [matrixObservable]="matrixObservable" 
          [ballotVotedObservable]="ballotVotedObservable" 
          [ballots_number]="ballots_number"
          [ballots_voted]="ballots_voted"
          [label_forReturn]="label_forReturn"
          (vote)="formationBallotVote($event)"
          (exit)="returnedFromVotations($event)">          
      </app-tb-ranking-votes>
    </ion-col> 
  </ion-row>

    <!-- Scroll Expand Trick -->
    <ion-row><ion-col><p>&nbsp;</p><p>&nbsp;</p></ion-col></ion-row>

</ion-grid>
