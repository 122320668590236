<ion-app>

  <app-main-header [ngClass]="{'is-ios': isPlatformIos}" [(IsNotRanking)]="IsNotRanking" [hide]="hasHeader === false"></app-main-header>

  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content" type="overlay">

      <ion-content color="primary">

        <ion-card color="primary" class="ion-no-margin" [ngClass]="{'has-header': hasHeader,'is-ios': isPlatformIos}" id="user-info">
          <ion-grid class="menu-header ion-no-padding">
            <ion-row>
              <ion-col size="8" class="ion-no-padding ion-align-self-center">
                <ion-card-header>
                  <ion-card-title>{{ userName }}</ion-card-title>
                  <ion-card-subtitle>{{ userEmail }}</ion-card-subtitle>
                </ion-card-header>
              </ion-col>
              <ion-col size="4">
                <ion-card-content class="ion-no-padding">
                  <ion-button class="button-logout" size="small" color="secondary" [routerLink]="['/logout']" (click)="currentPath='/logout'">
                    <ion-text i18n>Logout</ion-text>
                  </ion-button>
                </ion-card-content>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>

        <ion-list class="menu-area">
          <!-- Main Menu -->
          <ion-menu-toggle auto-hide="false">

            <ion-list-header class="section-header" i18n>
              Menu principale
            </ion-list-header>

            <ion-item routerDirection="root" lines="none" detail="false" (click)="setcurrentPath('/home', '/home')" [class.selected]="currentPath == '/home'">
              <!-- <ion-icon slot="start" [ios]="'pricetag-outline'" [md]="'pricetag-sharp'"></ion-icon> -->
              <ion-label i18n>Home</ion-label>
            </ion-item>

            <ion-item (click)="openMyBallotsPage()"
                      routerDirection="root" [routerLink]="['/my-ballots']" lines="none" detail="false"  (click)="setcurrentPath(null)" [class.selected]="currentPath == '/my-ballots'">
              <ion-label i18n>I miei ballottaggi</ion-label>
            </ion-item>

            <ion-item *ngIf="showCatalog && !isGuest" routerDirection="root" [routerLink]="['/catalog']" lines="none" detail="false" (click)="setcurrentPath('/catalog')" [class.selected]="currentPath == '/catalog'">
              <!-- <ion-icon slot="start" [ios]="'pricetag-outline'" [md]="'pricetag-sharp'"></ion-icon> -->
              <ion-label i18n>Catalogo</ion-label>
            </ion-item>

            <!-- Intercetta i parametri da <app-countdown-minute> che, lanciato da questa pagina, non ha grafica -->
            <ion-item *ngIf="!isGuest" lines="none" detail="false" button (click)="openRankingPage()">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="4" class="ion-no-padding ion-align-self-center">
                    <ion-label i18n>Ranking</ion-label>
                  </ion-col>
                  <ion-col size="6" class="ion-no-padding ion-align-self-center ion-text-center">
                    <ion-button expand="block" class="ranking-counter">{{timeCountdown}}</ion-button>
                  </ion-col>
                  <ion-col size="2" class="ion-no-padding ion-align-self-center ion-text-right">
                    <img [src]="urlCountdown" class="ranking-icon" />
                  </ion-col>
                </ion-row>
              </ion-grid>
              <app-countdown-minute [programmaChiamante]="programmaChiamante"
                                    (parametriAlChiamante)="riceviParametriDalCountdown($event)">
              </app-countdown-minute>
            </ion-item>

            <ion-item lines="none" detail="false" button (click)="openFantapazzDynamicLink()">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col size="4" class="ion-no-padding ion-align-self-center">
                    <ion-label i18n>Fantapazz</ion-label>
                  </ion-col>
                  <ion-col size="6" class="ion-no-padding ion-align-self-center ion-text-center">
                    <ion-button expand="block" class="fantapazz-action">VAI ALLE LEGHE</ion-button>
                  </ion-col>
                  <ion-col size="2" class="ion-no-padding ion-align-self-center ion-text-right">
                    <img src="/assets/icons/fantapazz.png" class="fantapazz-icon">
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>

            <ion-item>
              <!-- <hr class="separator"> -->
            </ion-item>

            <ion-list-header class="section-header" i18n>
              Social
            </ion-list-header>

            <ion-item lines="none" detail="false" button (click)="openSocialUrl('instagram')">
              <ion-label i18n>Seguici su Instagram!</ion-label>
              <ion-icon slot="end" name="logo-instagram" color="secondary"></ion-icon>
            </ion-item>

            <ion-item lines="none" detail="false" button (click)="openSocialUrl('facebook')">
              <ion-label i18n>Seguici su Facebook!</ion-label>
              <ion-icon slot="end" name="logo-facebook" color="secondary"></ion-icon>
            </ion-item>

            <ion-item>
              <!-- <hr class="separator"> -->
            </ion-item>

            <!-- <ion-list-header class="section-header" button (click)="toggleMore($event)" i18n>
              <ion-label>Altro...
                <ion-icon *ngIf="showMore === false" name="chevron-down"></ion-icon>
                <ion-icon *ngIf="showMore === true" name="chevron-up"></ion-icon>
              </ion-label>
            </ion-list-header> -->

            <ion-item class="as-list-header section-header" lines="none" detail="false" button (click)="toggleMore($event)" color="secondary" i18n>
              <ion-label color="secondary">Altro...</ion-label>
              <ion-icon *ngIf="showMore === false" name="chevron-down"></ion-icon>
              <ion-icon *ngIf="showMore === true" name="chevron-up"></ion-icon>
            </ion-item>

            <ng-container *ngIf="showMore">
              <ion-item lines="none" detail="false" button (click)="openPrivacyPolicyUrl()">
                <ion-label i18n>Politica sulla privacy</ion-label>
              </ion-item>

              <ion-item lines="none" detail="false" button (click)="openTermsAndConditionsUrl()">
                <ion-label i18n>Termini e condizioni</ion-label>
              </ion-item>

              <ion-item lines="none" detail="false" button (click)="deleteAccount()">
                <ion-label i18n>Elimina account</ion-label>
              </ion-item>
            </ng-container>

            <ion-item class="ion-padding">
              <hr class="separator" />
            </ion-item>

          </ion-menu-toggle>

        </ion-list>

      </ion-content>

    </ion-menu>

    <ion-router-outlet id="main-content" [ngClass]="{'has-banner': hasUserAds, 'has-header': hasHeader, 'is-ios': isPlatformIos}"></ion-router-outlet>

  </ion-split-pane>

</ion-app>
