<!-- <div *ngIf="programmaChiamante=='Ranking1Page'">
	<ion-card class="timer ion-align-items-center ion-text-center ion-padding" >
		<br>
			<p>{{firstDescription}}</p>
			<p> {{secondDescription}}</p>
			<div *ngIf="flShowCountdown==true && seconds>=0">
				<div *ngIf="days>0">
					<span > {{days}} </span>gg
				</div>
				<div *ngIf="hours>0">
					<span > {{hours}} </span>hh
				</div>
				<span > {{minutes}} </span>mm
				<span > {{seconds}} </span>ss	
			</div>
		<br><br>
	</ion-card>	
</div> -->
<div *ngIf="programmaChiamante=='Ranking1Page'">
    <!-- <p>programmaChiamante==Ranking1Page</p> -->
    <ion-card class="timer ion-align-items-center ion-text-center ion-padding" >
        <br>
            {{firstDescription}}<br>
             {{secondDescription}}<br>
            <div *ngIf="flShowCountdown==true && seconds>=0" class="timerX">
				<div>
					<span *ngIf="days>0"> {{days}} g</span>  &nbsp; &nbsp; <span *ngIf="hours>0"> {{hours}} h</span>  <span > {{minutes}} m</span> <span *ngIf="minutes==0"> {{seconds}} s </span>
				</div>
				
                <!-- <div *ngIf="days>0">
                    <span > {{days}} </span>g 
                </div>
                <div *ngIf="hours>0">
                    <span > {{hours}} </span>h
                </div>
                <span > {{minutes}} </span>m -->
                <!-- <span *ngIf="minutes==0"> {{seconds}} </span>s   -->
            </div>
        <br><br>
    </ion-card> 
</div>




<div *ngIf="programmaChiamante=='RankingballotsPage'" class="timerForVotes">
			<div *ngIf="flShowCountdown==true && seconds>=0" style="color: white;">
				&nbsp;  &nbsp; Hai ancora :<span *ngIf="days>0" class="gray"><b> {{days}} gg </b>  &nbsp; </span><span *ngIf="hours>0" class="gray"><b> {{hours}} h </b></span>
				<span class="gray"><b> {{minutes}} m </b></span>
				<!-- <span > {{seconds}} </span>ss	 -->
			</div>
			<div *ngIf="thirdDescription">
				&nbsp; ATTENZIONE! {{thirdDescription}}
			</div>			
</div>
 
 