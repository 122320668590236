import { CountdowntModule } from '../../components/countdown/countdown.module';
//import { CountdowntMinuteModule } from './../../components/countdown-minute/countdown-minute.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { RankingHeaderComponent } from './ranking-header.component';
import { Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    component: RankingHeaderComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,    
    CountdowntModule
  ],
  declarations: [RankingHeaderComponent],
  exports: [RankingHeaderComponent]
})
export class RankingHeaderPageModule {}
