import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, NgZone, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Browser } from '@capacitor/browser';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PNService {

  onRegistration: EventEmitter<string> = new EventEmitter<string>();
  onRegistrationError: EventEmitter<any> = new EventEmitter<any>();
  onPushNotificationReceived: EventEmitter<PushNotificationSchema> = new EventEmitter<PushNotificationSchema>();
  onPushNotificationActionPerformed: EventEmitter<ActionPerformed> = new EventEmitter<ActionPerformed>();

  isInitialized: boolean = false;
  onRegistrationCallback: CallableFunction|null = null;
  hasPermissions: boolean|null = null;

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private httpClient: HttpClient
  ) {

  }

  async initialize(onRegistrationCallback: CallableFunction|null = null) {
    if (this.isInitialized === true) { return; }
    this.mapListenersToEvents(onRegistrationCallback);
    this.hasPermissions = await this.requestPermissionsAndRegister();
    this.isInitialized = true;
  }

  mapListenersToEvents(onRegistrationCallback: CallableFunction|null = null) {
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('Push registration success, token: ' + token.value);
      this.onRegistration.emit(token.value);
      if (!!onRegistrationCallback) {
        onRegistrationCallback(token.value);
      }
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
      this.onRegistrationError.emit(error);
    });

    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        this.onPushNotificationReceived.emit(notification);
    });

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        this.navigateTo(notification);
        this.onPushNotificationActionPerformed.emit(notification);
    });
  }

  public async removeAllListeners() {
    await PushNotifications.removeAllListeners();
  }

  public async removeAllDeliveredNotifications() {
    await PushNotifications.removeAllDeliveredNotifications();
  }

  async requestPermissionsAndRegister(): Promise<boolean> {
    return new Promise((resolve) => {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
          resolve(true);
        } else {
          // Show some error
          resolve(false);
        }
      });
    });
  }

  public async sendPushNotification(tokenOrTokens: string|Array<string>, title: string, body: string, data: any) {
    const payload: any = {
        notification: { title, body },
        data, //: typeof data === 'string' ? data : JSON.stringify(data),
        // Specific for "Apple Push Notifications Service"
        apns: {
          payload: {
            aps: {
              badge: 1,
              sound: 'default',
              alert: {
                title,
                body
              }
            },
            data, //: typeof data === 'string' ? data : JSON.stringify(data)
          }
        },
    };

    // Single cast or multicast
    let servicePath = null;
    if (typeof tokenOrTokens === 'string') {
      servicePath = 'send-to-one';
      payload.token = tokenOrTokens;
    } else {
      servicePath = 'send-to-many'
      payload.tokens = tokenOrTokens;
    }

    this.httpClient.post(`${environment.pushNotificationsServerUrl}:${environment.pushNotificationsServerPort}/${servicePath}`, {
      authToken: environment.pushNotificationsServerAuthToken,
      payload
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
    .toPromise()
    .then((response) => {
      console.log('PNService.sendPushNotification() response -> ', response);
    })
    .catch((error) => {
      console.log('PnServive.sendPushNotification() catch error -> ', error);
    });
  }

  async listChannels() {
    return new Promise((resolve) => {
      PushNotifications.listChannels()
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          resolve(error);
        });
    });
  }

  private navigateTo(notification: ActionPerformed) {
    this.ngZone.run(async () => {
      const data = notification.notification.data;
      if (!!data.navigateTo === false) { return; }
      // Internal or External navigation app link
      if (data.navigateTo.indexOf('https') === -1 && data.navigateTo.indexOf('http') === -1 ) {
        return this.router.navigateByUrl(data.navigateTo);
      } else {
        return Browser.open({ url: data.navigateTo });
      }
    });
  }

}





