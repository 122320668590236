export class Helpers {

  public static extractBallotMatrixToArray(ballotMatrix: string) {
    const matrix = ballotMatrix.toLowerCase();
    if (matrix.indexOf('x') > -1) {
      return matrix.split('x');
    }
    return matrix.split(':');
  }

}
