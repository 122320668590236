import { environment } from './../../environments/environment';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { DataService } from './data.service';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppStorageService } from './app-storage.service';

// Capacitor
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';
import { Browser, OpenOptions } from '@capacitor/browser';
import { HttpClient } from '@angular/common/http';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  endingDates;
  tasks = {
    endingDate: null,
    cacheCleared: false,
    endDateMessageShown: false
  };

  static USER_STORAGE_KEY = 'FantaballottaggiFantapazz.ccUser';
  static TOKEN_STORAGE_KEY = 'FantaballottaggiFantapazz.ccToken';
  static APP_BUILD_STORAGE_KEY = 'FantaballottaggiFantapazz.AppBuild';
  static APP_VERSION_STORAGE_KEY = 'FantaballottaggiFantapazz.AppVersion';

  deviceInfo: any;
  appEnvironment;
  appBuild;
  appVersion;
  isUpdateRequired = null;

  apiRequestHeaders = { 'Content-Type': 'application/json', Accept: 'application/json' };

  networkStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  appBuildStored: number;
  appVersionStored: any;

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private router: Router,
    private platform: Platform,
    private httpClient: HttpClient,
    private appStorageService: AppStorageService,
    private dataService: DataService
  ) {
    this.init();
  }

  async init() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.clearCacheAndRefreshData();
      }
    });

    this.pollNetworkStatus();
  }

  async pollNetworkStatus() {
    // status = { "connected": true, "connectionType": "wifi" }

    Network.addListener('networkStatusChange', (status: any) => {
      this.networkStatus.next(status);
      // console.log("Network status changed", status);
    });

    const status: any = await Network.getStatus();
    this.networkStatus.next(status);
    // console.log("GOT Network status", status);
  }

  async onInitializeApp() {
    await this.refreshEndingDates();
    await this.clearCacheAndRefreshData();
  }

  async onNewTurboBallot(ballot) {
    this.clearCacheAndRefreshData();
  }

  async getStoredTasks() {
    let tasks = await this.appStorageService.get(environment.ENDING_DATE_TASKS_KEY);
    if (typeof tasks === 'string') {
      tasks = JSON.parse(tasks);
    }
    return tasks;
  }

  async setStoredTasks(tasks) {
    if (typeof tasks !== 'string') {
      tasks = JSON.stringify(tasks);
    }
    let storedTasks = await this.appStorageService.set(environment.ENDING_DATE_TASKS_KEY, tasks);
    if (typeof storedTasks === 'string') {
      storedTasks = JSON.parse(storedTasks);
    }
    return storedTasks;
  }

  async getTasks() {
    const endingDates: any = await this.getEndingDates();
    if (!!endingDates === false) { return false; }

    // Check if tasks are already in memory or take from storage
    if (!!this.tasks === false || !!this.tasks.endingDate === false || typeof this.tasks.endingDate !== 'string') {
      const tasks = await this.getStoredTasks();
      if (!!tasks && !!tasks.endingDate) { this.tasks = tasks; }
    }

    // Check if tasks are already stored and are correct or initialize and store now
    if (!!this.tasks === false || !!this.tasks.endingDate === false || typeof this.tasks.endingDate !== 'string') {
      this.tasks.endingDate = endingDates.prev;
      this.tasks.cacheCleared = false;
      this.tasks.endDateMessageShown = false;
      this.tasks = await this.setStoredTasks(this.tasks);
    }

    // Check if tasks are expired and renew
    const freshDate = endingDates.prev;
    const storedDate = this.tasks.endingDate;

    if (freshDate !== storedDate) {
      this.tasks.endingDate = endingDates.prev;
      this.tasks.cacheCleared = false;
      this.tasks.endDateMessageShown = false;
      this.tasks = await this.setStoredTasks(this.tasks);
    }

    return this.tasks;
  }

  async updateTasks(key, value) {
    const tasks = await this.getTasks();
    if (!!tasks === false) {
      console.log('MaintenanceService.updateTasks(): no [tasks] available. TODO - Check why');
      return;
    }
    tasks[key] = value;
    this.tasks = await this.setStoredTasks(tasks);
    return this.tasks;
  }

  async refreshEndingDates() {
    const response = await this.dataService.httpGet(`/maintenance/get-last-current-next-ending-dates`);
    if (!!response === false || !!response.error === true) { return; }
    if (!!response.data === false || response.data.length === 0) { return; }
    const storedEndingdates = await this.appStorageService.set(environment.ENDING_DATES_STORAGE_KEY, response.data);
    return storedEndingdates;
  }

  async getEndingDates() {
    if (!!this.endingDates === false) {
      this.endingDates = await this.appStorageService.get(environment.ENDING_DATES_STORAGE_KEY);
    }
    if (!!this.endingDates === false) {
      this.endingDates = await this.refreshEndingDates();
    }
    return this.endingDates;
  }

  async clearCacheAndRefreshData(force = false) {
    const tasks: any = await this.getTasks();
    if (!!tasks.cacheCleared && !!force === false) { return; }

    const excludedKeys = [
      'accessToken',
      'userData',
      environment.ENDING_DATE_TASKS_KEY,
      environment.VOTED_BALLOTS_COUNT_KEY,
      environment.VIDEO_REWARDED,
      environment.TIMES_REWARDED
    ];

    const keys = await this.appStorageService.keys();
    keys.forEach(async (key) => {
      if (excludedKeys.indexOf(key) === -1) {
        await this.appStorageService.remove(key);
        console.log(`[${key}] was removed from cache by MaintenanceService.clearCacheAndRefreshData() method.
                    If you want to persit this data add to "excludedKeys" array inside method.`);
      }
    });

    await this.updateTasks('cacheCleared', true);
  }

  async openAppInStore() {

    if (this.platform.is('capacitor') === false) { return; }

    let linkUrl = null;

    if (this.platform.is('ios')) {
      linkUrl = `https://apps.apple.com/it/app/fantaballottaggi-fantapazz/id1462616033`;
    }

    if (this.platform.is('android')) {
      const deviceInfo = await Device.getInfo();
      linkUrl = `https://play.google.com/store/apps/details?id=it.redfoxlabs.fantaballottaggi`;
    }

    if (!!linkUrl === false) {
      return;
    }

    window.open(linkUrl, '_system');
    //await this.openLink(linkUrl);
  }

  async openLink(url) {
    const options: OpenOptions = { url };
    await Browser.open(options);
  }

  async getAppInfo() {
    // Get platform specific app ionfo
    if (this.platform.is('capacitor')) {
      // Native platform
      if (!!this.deviceInfo === false) {
        this.deviceInfo = await Device.getInfo();
      }

      this.appEnvironment = 'native';
      this.appBuild = this.deviceInfo.appBuild;
      this.appVersion = this.deviceInfo.appVersion;
    } else {
      // Web platform
      this.appEnvironment = 'web';
      this.appBuild = environment.appBuildWeb;
      this.appVersion = environment.appVersionWeb;
    }

    // Get stored data version
    this.appBuild = environment.appBuildWeb;
    //this.appBuildStored = parseInt((!!appBuild ? appBuild : '-1'), 10);
    this.appVersionStored = await this.appStorageService.get(MaintenanceService.APP_VERSION_STORAGE_KEY);

    return {
      appEnvironment: this.appEnvironment,
      appBuild: this.appBuild,
      appVersion: this.appVersion,
      deviceInfo: this.deviceInfo
    };
  }

  async storeAppInfo() {
    this.appStorageService.set(MaintenanceService.APP_BUILD_STORAGE_KEY, this.appBuild);
    this.appStorageService.set(MaintenanceService.APP_VERSION_STORAGE_KEY, this.appVersion);
  }

  async updateRequired() {
    //if (this.isUpdateRequired === null) {
      const isAppOutdated = await this.isAppOutdated();
      this.isUpdateRequired= isAppOutdated;
      return this.isUpdateRequired;
    //}

  }


  async getAvailableVersion() {
    return new Promise((resolve) => {

      this.httpClient.get(`${environment.url}/maintenance/available-version`, {
        headers: this.apiRequestHeaders
      })
      .toPromise()
      .then((response: any) => {
        if (!!response.error) {
          console.log('MaintenanceService.getAvailableVersion() then() error ->', response);
          return resolve(null);
        }
        return resolve(response.data);
      })
      .catch((error) => {
        console.log('MaintenanceService.getAvailableVersion() catch() error -> ', error);
        return resolve(null);
      });

    });
  }

  async isAppOutdated() {
    const appInfo = await this.getAppInfo();
    // if (appInfo.appEnvironment === 'web' || !!appInfo === false) { return false; }

    const availableVersion: any = await this.getAvailableVersion();
    if (!!availableVersion === false || !!availableVersion.versionCode === false) { return false; }

    const availableBuild = typeof availableVersion.versionCode === 'string' ? parseInt(availableVersion.versionCode, 10) : availableVersion.versionCode;
    const currentBuild = typeof appInfo.appBuild === 'string' ? parseInt(appInfo.appBuild, 10) : appInfo.appBuild;

    if (availableBuild > currentBuild) {
      if (availableVersion.blockOutdatedApp === true) {
        return true;
      }
      return true; // DA ELIMINARE
    }
    return false;
  }


}
