/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { format, formatISO } from 'date-fns';
import { ApiService } from './api.service';
import { Communications } from '../models/communications';
import { StorageService } from './storage.service';
import { AppStorageService } from './app-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CommunicationsService {

  constructor(
    private appStorageService: AppStorageService,
    private apiService: ApiService
  ) { }

  async list(page: number = 1, perPage: number = 25): Promise<Array<Communications>> {
    const token = await this.getAccessToken();
    const url = '/communications/list';

    const params: any = {};

    if (!!page && !!perPage) {
      params.page = page;
      params.per_page = perPage;
    }

    const response = await this.apiService.httpGet(url, params, token);

    // if (!!page && !!perPage) {
    //   return response.data.map((item: any) => Communications.fromObjectWithSnakeCase(item, new Communications()));
    // }

    return response.data.map((item: any) => Communications.fromObject(item, new Communications()));
  }

  async get(id: number) {
    const token = await this.getAccessToken();
    const url = `/communications/get/${id}`;
    const response = await this.apiService.httpGet(url, {}, token);
    return response.data;
  }

  async save(data: Communications) {
    const token = await this.getAccessToken();
    const url = '/communications/save';

    const body: any = {
      id: data.id || null,
      warning_message: data.warning_message,
    };
    console.log(body);

    const response = await this.apiService.httpPost(url, body, token);
    return response.data;
  }

  async delete(id: number) {
    const token = await this.getAccessToken();
    const url = `/communications/delete/${id}`;
    const response = await this.apiService.httpDelete(url, {}, token);
    return response.data;
  }

  async getAccessToken() {
    const accessToken = await this.appStorageService.get('accessToken');
    return accessToken;
  }

}
