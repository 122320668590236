import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-tb-ranking-votes',
  templateUrl: './tb-ranking-votes.component.html',
  styleUrls: ['./tb-ranking-votes.component.scss'],
})
export class TbRankingVotesComponent implements OnInit, OnChanges {

  @Output() vote: EventEmitter<any> = new EventEmitter();
  @Output() exit: EventEmitter<any> = new EventEmitter();

  @Input() ballotObservable: BehaviorSubject<any>;
  @Input() matrixObservable: BehaviorSubject<any>;
  @Input() ballotVotedObservable: BehaviorSubject<any>;
  @Input() ballots_voted: number 
  @Input() ballots_number: number 
  @Input() label_forReturn: string

  votazionIsCompleted: number = -99 // inserito per visualizzare il messaggio 'prosegui'
  matrix0
  matrix1
  animate = true;
  // ------------------------------------
  freezed = false;
  selectedValue: Array<number> = new Array<number>();
  totalVotes: number = null;
  votes: Array<any> = null;
  cardsSize = 'default';
  arePlayersOdd = false;
  animateSelected = false;


  ritorno:string

  constructor() { }
  ngOnInit() { 
    
  }
  ngOnChanges() {
    this.selectedValue = []
    
    if(this.ballotObservable.value){
      this.votazionIsCompleted = null
      this.ballotObservable.subscribe(ballot => this.ballotLoaded(ballot));
      this.matrixObservable.subscribe(matrix => this.matrixLoaded(matrix));
      this.matrix0 = this.matrixObservable.value[0]
      this.matrix1 = this.matrixObservable.value[1]
      this.ballotVotedObservable.subscribe(votedBallot => this.ballotVoted(votedBallot));   
    }
  }

  ballotLoaded(ballot) {
    this.setCardsSize();
    this.setArePlayersOdd();
    this.unfreeze();
  }
  matrixLoaded(matrix) { }
  ballotVoted(votedBallot) {
    if (!!votedBallot) {
      this.totalVotes = votedBallot.total_votes;
      this.votes = votedBallot.votes;
    }
  }

  emitVote(selectedValue) {
    // ---------------------------------------------------------
    // restituzione dei players votati al chiamante
    // ---------------------------------------------------------
    this.freeze();
    this.votazionIsCompleted = 0
    this.vote.emit(selectedValue);
  }
  exitFromVotation($event) {    
    // ---------------------------------------------------------
    // ritorno al chiamane, per il nuovo ballottaggio o l'uscita
    // ---------------------------------------------------------
    this.exit.emit();
  }

  setCardsSize() {
    if (!!this.ballotObservable.value && this.ballotObservable.value.players) {
      this.cardsSize = this.ballotObservable.value.players.length > 2 ? 'small' : 'default';
    }
  }

  setArePlayersOdd() {
    if (!!this.ballotObservable.value && this.ballotObservable.value.players) {
      this.arePlayersOdd = (this.ballotObservable.value.players.length % 2 !== 0);
    }
  }

  toggleSelection(playerId) {
    console.log("click1")
    // attivata al 'click' della votazione sul player
    if (this.isFreezed()) { return; }
    const index = this.selectedValue.indexOf(playerId);
    if (index === -1) {
      this.selectedValue.push(playerId);
    } else {
      this.selectedValue.splice(index, 1);
    }
    if (this.selectedValue.length === parseInt(this.matrixObservable.value[0], 10) ) {
      this.emitVote(this.selectedValue);
    }
    // Trigger animation
    this.animateSelected = true;
    const t = setTimeout(() => {
      this.animateSelected = false;
      this.exitFromVotation(null);
      clearTimeout(t);
    }, 2000);
  }

  isSelected(playerId) {
    if (!!this.selectedValue === false) { return false; }
    const index = this.selectedValue.indexOf(playerId);
    return index > -1;
  }

  freeze() {
    this.freezed = true;
  }
  unfreeze() {
    this.freezed = false;
  }
  isFreezed() {
    return this.freezed;
  }

}
