import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AlertController, MenuController, LoadingController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { PlayersService } from './services/players.service';
import { CatalogService } from './services/catalog.service';
import { MaintenanceService } from './services/maintenance.service';
import { BehaviorSubject } from 'rxjs';
import { BallotsService } from './services/ballots.service';

// Capacitor
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { SplashScreen } from '@capacitor/splash-screen';
import { AdMobService } from './services/ad-mob.service';
import { PNService } from './services/pn.service';

// Swiper register once
import { register } from 'swiper/element/bundle';
import { ToastService } from './services/toast.service';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  programmaChiamante: string = "AppComponent" // PARAMETRI DEL COUNTDOWN
  timeCountdown: string
  IsNotRanking: boolean = true;
  expiration_date_ballot: string = null;
  urlCountdown = "/assets/images/ranking-trofeo.png";

  currentPath;
  hasHeader = true;
  userName;
  userEmail;
  showCatalog = true;
  isGuest = false;
  isPlatformIos = false;

  hasUserAds: boolean = false;
  showMore = false;

  dummyCounterForMyBallotsPage = 0;
  productObservable: BehaviorSubject<any> = new BehaviorSubject<any>({});
  ownedObservable: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public transactionExpired: any;

  lastTransaction: any = null;
  process_active: boolean;

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private platform: Platform,
    private userService: UserService,
    private location: Location,
    private menuCtrl: MenuController,
    private router: Router,
    private playersService: PlayersService,
    private catalogService: CatalogService,
    private adMobService: AdMobService,
    private ballotsService: BallotsService,
    private maintenanceService: MaintenanceService,
    private pnService: PNService
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    // Exit from app wneh touch back button
    App.addListener('backButton', async () => {
      App.exitApp();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.menuCtrl.close();
        this.currentPath = this.location.path();
        this.toggleHeader();
      }
    });

    // Check if there is logged user and subscribe to loggedUser updates
    const loggedUser = await this.userService.loggedUserObservable.getValue();
    this.setLoggedUserData(loggedUser);

    this.userService.loggedUserObservable.subscribe(async (loggedUserNext) => {
      this.setLoggedUserData(loggedUserNext);
    });

    // Subscribe to N votes
    this.ballotsService.onNVotesCountNotification.subscribe((votesCount: number) => {
      // Interstitial Ads
      if (!!this.hasUserAds && !!environment.admob.interstitialEnabled) {
        this.adMobService.showInterstitial();
      }
    });

    await this.preloadDataToCache();
  }

  setcurrentPath(path: string | null, redirect?: string) {
    if (!!path) this.currentPath = path;
    this.IsNotRanking = true;
    console.log("Corretto il settaggio", this.IsNotRanking)
    if (!!redirect) {
      this.router.navigate([redirect]);
    }
  }

  setLoggedUserData(loggedUser: any) {
    if (!!loggedUser === false) { return; }

    this.userName = loggedUser.name;
    this.userEmail = loggedUser.email;
    this.isGuest = this.userService.isUserGuest();
    this.showCatalog = !this.userService.isUserPro();

    this.hasUserAds = (!this.userService.isUserPro() && !!loggedUser.privacy_policy_accepted) || this.userService.isUserGuest();
    if (this.hasUserAds === true) {
      this.initializeAds();
    }

    this.pnService.initialize((token: string) => {
      let tokens = [];
      if (!!loggedUser.push_notifications_tokens) {
        tokens = typeof loggedUser.push_notifications_tokens === 'string' ? JSON.parse(loggedUser.push_notifications_tokens) : loggedUser.push_notifications_tokens;
      }

      if (tokens.indexOf(token) === -1) {
        tokens.push(token);
      }

      // Max 3 tokens per user, remove older
      if (tokens.length > 3) {
        tokens.splice(0, 1);
      }

      this.userService.updateUser({ id: loggedUser.id, push_notifications_tokens: JSON.stringify(tokens) });
      this.pnService.removeAllDeliveredNotifications();
    });

    this.pnService.onPushNotificationActionPerformed.subscribe(() => {
      this.pnService.removeAllDeliveredNotifications();
    });
  }

  async initializeApp() {
    SplashScreen.hide({ fadeOutDuration: 0 });

    // Check for connection
    this.maintenanceService.networkStatus.subscribe(async (status: any) => {
      if (!!status && status.connected === false) {
        await this.showNotConnectedMessage();
      }
    });

    const updateRequired = await this.maintenanceService.updateRequired();
    //console.log("verifico aggiornamento",updateRequired)
    if (!!updateRequired) {
      this.showUpdateApp();
    }

    this.maintenanceService.onInitializeApp();

    this.platform.ready().then(async () => {
      this.isPlatformIos = this.platform.is('ios');
    });
  }

  async initializeAds() {
    // ADMob
    await this.adMobService.initialize();
    await this.adMobService.showBanner();
  }

  async showNotConnectedMessage() {
    const connectivityWarningAlert = await this.alertController.create({
      header: 'Nessuna connessione a internet',
      message: 'Connessione internet interrotta. Per continuare ad usare l\'app accertati di attivare il WiFi o la connessione dati del tuo dispositivo e di avere una connettività accettabile.',
      backdropDismiss: false,
      buttons: [{
        text: 'Riprova',
        role: 'dismiss',
        handler: async () => {
          const status = this.maintenanceService.networkStatus.getValue();
          if (!!status && status.connected === false) {
            await this.showNotConnectedMessage();
          } else {
            // window.location.assign('/');
            this.router.navigate(['/home']);
          }
        }
      }]
    });
    await connectivityWarningAlert.present();
  }

  async showUpdateApp() {
    const updateApp = await this.alertController.create({
      header: 'Aggiornamento richiesto',
      message: "Vai nello Store e aggiorna l'app! Fantaballottaggi è in continua evoluzione! Per poter continuare ad usare l\'app è necessario aggiornarla.",
      backdropDismiss: false,
      buttons: [{
        text: 'Aggiorna',
        handler: async () => {
          let p = await this.maintenanceService.openAppInStore();

        }
      }]
    });
    let res = await updateApp.present();
  }

  async preloadDataToCache() {
    await this.catalogService.getCatalogItems();
    await this.playersService.getAll(true);
  }

  toggleHeader() {
    const pathsWithNoHeader = ['/splash', '/login', '/logout', '/register', '/legals'];
    this.hasHeader = pathsWithNoHeader.findIndex(path => this.currentPath.startsWith(path)) === -1;
  }

  openMyBallotsPage() {  // AGGIUNTA LUGLIO 2021
    this.dummyCounterForMyBallotsPage++ // incrementa il contatore per forzare il passaggio da MyBallotPage/ngOnInit()
    this.currentPath = '/my-ballots'
    this.router.navigate(['my-ballots', this.dummyCounterForMyBallotsPage]);
  }

  async openRankingPage() {
    this.IsNotRanking = false;
    this.router.navigate(['/ranking1']);
  }

  async openFantapazzDynamicLink() {
    // window.open(environment.fantapazzDynamicLinkUrl, '_system');
    //this.IsNotRanking=true;
    await Browser.open({ url: environment.fantapazzDynamicLinkUrl });
  }

  async openPrivacyPolicyUrl() {
    await Browser.open({ url: environment.privacyPolicyUrl });
  }

  async openTermsAndConditionsUrl() {
    await Browser.open({ url: environment.termsAndConditionsUrl });
  }

  async openSocialUrl(socialName) {
    switch (socialName) {
      case 'facebook':
        if (this.platform.is('android')) {
          window.open(`fb://page/${environment.facebookPageId}`, '_system');
          window.open(`${environment.facebookUrl}`, '_system');
        } else {
          window.open(`fb://page/${environment.facebookPageId}`, '_system');
          window.open(`${environment.facebookUrl}`, '_system');
          // await Browser.open({ url: environment.facebookUrl });
        }
        break;
      case 'instagram':
        window.open(environment.instagramUrl, '_system');
        // await Browser.open({ url: environment.instagramUrl });
        break;
      default:
        break;
    }
  }

  toggleMore($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    this.showMore = !this.showMore;
  }

  async deleteAccount() {
    const alert = await this.alertController.create({
      header: 'Stai per cancellare il tuo account',
      message: 'I tuoi dati verranno cancellati e sarai immediatamente disconnesso dall\'applicazione. Sei sicuro di voler procedere?',
      buttons: [{
        text: 'Cancella Account',
        role: 'confirm',
        handler: async () => {
          const loading = await this.loadingController.create({ message: 'Operazione in corso...' });
          loading.present();
          const currentUser = await this.userService.getLoggedUser();
          const deleteResult = await this.userService.deleteAccount(currentUser.id);
          loading.dismiss();
          this.router.navigate(['/login']);
        }
      }, {
        text: 'Annulla',
        role: 'cancel'
      }]
    });

    alert.present();
  }

  riceviParametriDalCountdown($event: any) {
    let params = $event
    this.timeCountdown = params.longDescription
    if (this.timeCountdown == null) {
      this.timeCountdown = "STAGIONE FINITA";
    }
  }
}
