import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toast: any = null
  constructor(private toastController: ToastController) { }

  async presentMediumToast(msg: string, duration = 3000, position: 'bottom' | 'middle' | 'top' = 'bottom') {
    const toast = await this.toastController.create({
      message: msg,
      color: 'medium',
      duration,
      position
    });
    toast.present();
  }


  async presentSuccessToast(msg: string, duration = 3000, position: 'bottom' | 'middle' | 'top' = 'bottom') {
    const toast = await this.toastController.create({
      message: msg,
      color: 'success',
      duration,
      position
    });
    toast.present();
  }

  async presentDangerToast(msg: string, duration = 3000, position: 'bottom' | 'middle' | 'top' = 'bottom') {
    const toast = await this.toastController.create({
      message: msg,
      color: 'danger',
      duration,
      position
    });
    toast.present();
  }



  async presentErrToast(msg: string, duration = 3000, position: 'bottom' | 'middle' | 'top' = 'bottom') {
    const toast = await this.toastController.create({
      message: msg,
      color: 'danger',
      duration,
      position,
      buttons: [
        {
          text: 'X',
          role: 'cancel'
          // handler: () => {
          //   console.log('Cancel clicked');
          // }
        }
      ]
    });
    toast.present();
  }

//   async presentErrToast(msg: string) {
//     const toast = await this.toastController.create({
//       message: msg,
//       color: 'medium',
//       showCloseButton: true
//       closeButtonText: 'X',
// //      duration: 2000
//     });
//     toast.present();
//   }


  // presentToast(text:string):void{
  //   let toastData = {
  //       message: text,
  //       duration: 3000,
  //       position: 'button'
  //   }

  //   this.showToast(toastData);
  // }

  // presentClosableToast(text:string):void{
  //     let toastData = {
  //         message: text,
  //         showCloseButton: true,
  //         closeButtonText: 'X',
  //         position: 'button'
  //     };

  //     this.showToast(toastData);
  // }

  // //private showToast(data:any):void{
  // showToast(data:any):void{
  //     this.toast ? this.toast.dismiss() : false;
  //     this.toast = this.toastCtrl.create(data);
  //     this.toast.present();
  // }


}



