<ion-header>
  <ion-toolbar color="primary">
    <ion-title></ion-title>
    <ion-buttons slot="end">
      <ion-button size="large" class="ion-no-margin gradient__primary-secondary" expand="full" (click)="closeModal()" >
        <ion-icon name="close-outline"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid *ngIf="!!this.rankingParams">
    <ion-row class="ion-align-items-center ion-text-center">
        <ion-col size="12" class="ion-align-items-center ion-text-center">
            <div class="heading ion-margin-top">
                <div class="heading-title">
                    <ion-text i18n>{{rankingParams.help_title}}</ion-text>
                    <!-- <ion-text i18n>{{help_title}}</ion-text>  -->
                </div>
                <div class="heading-subtitle">
                  <!-- <ion-text i18n [innerHTML]="rankingParams.help_subtitle"></ion-text> -->
                  <ion-text i18n [innerHTML]="rankingParams.help_subtitle"></ion-text>
                </div>
            </div>
          </ion-col>
      </ion-row>
  </ion-grid>

  <ion-card  *ngIf="!!this.rankingParams" class="text-help">
    <!-- <div [innerHTML]="rankingParams.help_text"></div> -->
    <div [innerHTML]="rankingParams.help_text"></div>
  </ion-card>


</ion-content>